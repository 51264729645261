import {Box, CardContent, Typography} from '@mui/material';
import ExperimentStatisticsPanel from "../ExperimentStatisticsPanel";
import DesignPanel from "../DesignPanel";
import NewDesignDetailsPanel from "../../../../visualizations/NewDesignDetailsPanel/NewDesignDetailsPanel"
import {ExperimentProps} from './ExperimentTabs';
import {FC} from 'react';

const ExperimentDetails: FC<ExperimentProps> = (
    {experiment,
        experimentDesign,
        experimentAnalysis,
        showProcessed}) => {
    const showDesign = () => {
        return experimentDesign?.conditions?.length > 0
    }
    const folder = experimentDesign?.experiment_folder
                        ? experimentDesign?.experiment_folder : experimentAnalysis?.experiment_folder

    const wrapComponent = (header: string, componentToWrap: JSX.Element): JSX.Element => {
        return <Box mb={4}>
            <Typography gutterBottom variant="h5" component="h2">
                {header}
            </Typography>
            {componentToWrap}
        </Box>
    }
    

    
    return <CardContent id={'details-toPdf'}>
        {wrapComponent('Overview', <NewDesignDetailsPanel experimentDesign={experimentDesign}
                                                          experimentFolder={folder} />)}
        {showProcessed &&
                wrapComponent(
                    'Analysis Parameters & Statistics',
                    <ExperimentStatisticsPanel
                        experimentName={experiment.experiment}
                        experimentDesign={experimentDesign}
                        experimentAnalysis={experimentAnalysis}
                    />
                )}
        {showDesign() && wrapComponent("Design", <DesignPanel experimentDesign={experimentDesign}/>)}

    </CardContent>
}
export default ExperimentDetails;