import {useContext} from 'react';
import {Typography} from '@mui/material';
import {NewDesignDetailsPanelDataCy} from "./NewDesignDetailsPanel.cy";
import {StyledListBox} from '../../Dashboard/ExperimentsPanel/StyledComponents';
import CopyToClipboard from "../../SharedComponents/CopyToClipboard";
import {ExperimentTypeContext} from '../../../store/ExperimentTypeContext';

type NewDesignDetailsPanelProps = {
    experimentDesign: any;
    experimentFolder: any;
    children?: React.ReactNode;
}

export default function NewDesignDetailsPanel({experimentDesign,
                                                  experimentFolder,
                                                  children}: NewDesignDetailsPanelProps) {
    const experimentType = useContext<ExperimentType>(ExperimentTypeContext)

    return (
        <StyledListBox mb={0} mt={1} ml={2}>
            <Typography data-cy={NewDesignDetailsPanelDataCy.projectName} component="li" variant="body1">
                <strong>Project Name:</strong> {experimentDesign?.projectName}
            </Typography>
            <Typography data-cy={NewDesignDetailsPanelDataCy.responsible} component="li" variant="body1">
                <strong>Responsible:</strong> {experimentDesign?.responsible}
            </Typography>
            <Typography data-cy={NewDesignDetailsPanelDataCy.collaborator} component="li" variant="body1">
                <strong>Collaborator:</strong> {experimentDesign?.collaborator}
            </Typography>
            <Typography data-cy={NewDesignDetailsPanelDataCy.benchling} component="li" variant="body1">
                <strong>Benchling:</strong> {experimentDesign?.benchling}
            </Typography>
            <Typography component="li" variant="body1">
                <strong>Experiment Folder:</strong>&nbsp;
                {experimentFolder}
                {experimentFolder && <CopyToClipboard folder={experimentFolder}/>}
            </Typography>
            {experimentType.value === 'GENERAL' &&
                <Typography component="li" variant="body1">
                    <strong>Number of Samples:</strong> {experimentDesign?.noOfSamples}
                </Typography>
            }
            {children}
        </StyledListBox>
    );
}