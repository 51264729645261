import {ExperimentFilters} from 'filters';
import {generateClient} from 'aws-amplify/api'
import * as queries from '../graphql/queries';
import {experimentTypes} from '../util/options';
import {Experiment} from 'experiment';
import {reverseCompareByDate} from '../CompareExperiments';
import {
    completedPublishStep,
    completedAnalysisStep,
    pcaAnalysisForLabelCheck
} from '../graphql/subscriptions';
import {getLabelEfficiency, getStats, getYeastReferenceExperiment} from '../graphql/queries';
import {ExperimentType, PDVersion, RunAnalysisInput, TMT, DiaAnalysisInput} from '../API';
import {startAnalysis, startDiaAnalysis} from '../graphql/mutations';
import {handleError} from '../util/util';

const experimentsService = {
        fetchExperimentStatusList: async (listRecordType: string, filter: ExperimentFilters) => {
            try {
                const result = await generateClient().graphql({query: queries.getExperimentList,
                variables: {recordType: listRecordType, filters: filter}});
            return result.data.getExperimentList?.items;
            } catch (error: any) {
                console.error('fetchExperimentStatusList Error:', error);
                handleError(error)
            }
            return []
    },
    fetchExperimentStats: async (experimentCode: string, filters: ExperimentFilters) => {
        let statsRecordType = experimentTypes.find(t => t.code === experimentCode)!.statsType;
        try {
            const result = await generateClient().graphql({query: queries.getExperimentStatistics,
                    variables: {recordType: statsRecordType, filters: filters}});
            console.log(`Yeah I got a result`, result)
            const experiments = result.data.getExperimentStatistics.items;
            for (let i = 0; i < experiments.length; i++) {
                const experiment = experiments[i] as unknown as Experiment;
                if (experiment) {
                    // @ts-ignore
                    experiment.date = new Date(experiment.date);
                }
            }
            // @ts-ignore
            experiments.sort(reverseCompareByDate)
            return experiments
        } catch (error: any) {
            console.error('fetchExperimentStats Error:', error);
            handleError(error)
        }
        return []
    },
    fetchStats: async (experiment: string, statsRecordType: string): Promise<any> => {
        try {
            const response = await generateClient().graphql({query: getStats,
                variables: {experiment, recordType: statsRecordType}})
            return response.data.getStats as unknown as any
        } catch (error: any) {
            console.error('fetchExperimentStats Error:', error);
            handleError(error)
        }
        return null
    },
    fetchYeastReferenceExperiment: async (instrument: string): Promise<[string, any]> => {
        try {
            const client = generateClient()
            const response = await client.graphql({query: getYeastReferenceExperiment,
                variables: {instrument}})
            const experiment: string = response.data.getYeastReferenceExperiment?.experiment as unknown as string
            const statsResponse = await client.graphql({query: getStats,
                variables: {recordType: 'QC_STATISTICS', experiment: experiment}})
            const data = statsResponse.data.getStats as unknown as any
            return [experiment, data]
        } catch (error: any) {
            console.error('fetchExperimentStats Error:', error);
            handleError(error)
        }
        return ['', null]
    },
    observePublishing: () => {
        return generateClient().graphql({query: completedPublishStep})
    },
    observeAnalysis: () => {
        return generateClient().graphql({query: completedAnalysisStep})
    },
    observePcaAnalysisForLabelCheck: () => {
        return generateClient().graphql({query: pcaAnalysisForLabelCheck})
    },
    doAnalysis: async (input: RunAnalysisInput) => {
        await generateClient().graphql({query: startAnalysis, variables: {input}})
    },
    doDiaAnalysis: async (input: DiaAnalysisInput) => {
        const result = await generateClient().graphql({query: startDiaAnalysis, variables: {input}})
        return result.data.startDiaAnalysis
    },
    doLabelEfficiency: async (experiment: string, psmFileName: string, tmt: TMT) => {
        try {
            const response = await generateClient().graphql({query: getLabelEfficiency,
                variables: {
                    psmFile: psmFileName,
                    tmt,
                    pdVersion: PDVersion.PD_2_5,
                    experimentName: experiment,
                    experimentType: ExperimentType.DEGRADATION}})
            return response.data.getLabelEfficiency
        } catch (error: any) {
            console.error('doLabelEfficiency Error:', error);
            handleError(error)
        }
        return undefined
    }
}

export default experimentsService