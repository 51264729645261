import {FC, useEffect, useState} from 'react';
import apiService from '../../services/apiService';
import details from '../../services/details';
import {ExperimentDownload} from '../../API';
import {PictureAsPdf as PdfIcon,
        CloudDownload as CloudDownloadIcon,
        ArrowDropDown as CaretIcon
} from '@mui/icons-material';
import {Button, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';

const ResourcesMenu: FC = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [resources, setResources] = useState<any[]|null>(null)
    const menuId = 'resource-menu'

    useEffect(() => {
        const fetch = async () => {
            const info = await apiService.fetchResource('PxpResources')
            if (info) {
                const parsed = JSON.parse(info.resource)
                setResources(parsed)
            }
        }
        fetch().then()
    }, []);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const downloadResource = async (resource: string) => {
        const presigned = await details.fetchExperimentDownloadUrl([resource], ExperimentDownload.STATIC)
        if (presigned && presigned[0] && presigned[0].url) {
            // @ts-ignore
            window.location = presigned[0].url
        }
    }

    const doDownload = (fileName: string) => {
        if (fileName) {
            downloadResource(fileName)
        }
        setAnchorEl(null)
    }

    const renderResource = (resource: any) => {
        const element = resource.key.endsWith('pdf') ? <PdfIcon fontSize="small"/> : <CloudDownloadIcon fontSize="small"/>
        return (
            <MenuItem key={resource.key} onClick={() => doDownload(resource.key)} >
                <ListItemIcon color="inherit">{element}</ListItemIcon>
                <ListItemText primary={resource.label} />
            </MenuItem>
        );
    }

    return (
        <>
            <Button id="resource-menu-button" color="inherit" aria-controls={menuId}
                    className="MuiButton-root"
                    disabled={resources === null}
                    aria-haspopup={true} onClick={handleClick} endIcon={<CaretIcon />}
                    sx={{textTransform: 'capitalize'}} >
                Resources
            </Button>
            <Menu id={menuId} anchorEl={anchorEl}
                  keepMounted open={!!anchorEl}
                  onClose={handleClose}
                  PopoverClasses={{ root: 'appBarMenuPopper', paper: 'appBarMenuPaper'}}>
                {resources?.map((r:any) => renderResource(r))}
            </Menu>
        </>
    )
}
export default ResourcesMenu