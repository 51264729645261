import {generateClient} from 'aws-amplify/api'
import * as queries from '../graphql/queries';
import {ExperimentDownload, PXPAnalysisMethod, PXPfileType} from '../API';
import ExperimentStatistic from './ExperimentStatistic';
import {completedDownloadConversion} from '../graphql/subscriptions';
import {createConvertedExperimentDownload} from '../graphql/mutations';
import {getExperimentContrasts} from '../graphql/queries';
import {handleError} from '../util/util';

const details = {
    fetchStatistics:  async (experimentName: string, statsRecordType: string) => {
        try {
            const result = await generateClient().graphql({
                query: queries.getExperimentStatistic,
                variables: {experiment: experimentName, recordType: statsRecordType}
            });
            return new ExperimentStatistic(result.data.getExperimentStatistic)
        } catch (error) {
            console.error('fetchStatistics Error:', error);
            handleError(error)
            return null
        }
    },
    fetchExperimentDownloadUrl: async (keyValues: string[], downloadType: ExperimentDownload) => {
        try {
            const result = await generateClient().graphql({query: queries.getExperimentDownloadUrl,
                variables: { keys: keyValues, experimentDownload: downloadType}})
            return result.data.getExperimentDownloadUrl
        } catch (error) {
            console.error('fetchExperimentDownloadUrl Error:', error);
            handleError(error)
            return null
        }
    },

    fetchDesign: async (experiment: string) => {
        try {
            const result = await generateClient().graphql({query: queries.getExperimentDesign,
                variables: {experiment: experiment}})
            return result.data.getExperimentDesign
        } catch (error) {
            console.error('fetchDesign Error:', error);
            handleError(error)
            return undefined
        }
    },

    fetchAnalysis: async (experiment: string) => {
        try {
            const result = await generateClient().graphql({query: queries.getExperimentAnalysis,
                variables: {experiment: experiment}})
            return result.data.getExperimentAnalysis;
        } catch (error) {
            console.error('fetchAnalysis Error:', error);
            handleError(error)
            return null
        }
    },

    fetchAnalysisForType: async (experiment: string, experimentType: ExperimentType) => {
        try {
            if (experimentType.value === 'FLASH_DEGRADATION') {
                const result = await generateClient().graphql({query: queries.getFlashAnalysis,
                    variables: {experiment: experiment}})
                return result.data.getFlashAnalysis;
            } else {
                const result = await generateClient().graphql({query: queries.getExperimentAnalysis,
                    variables: {experiment: experiment}})
                return result.data.getExperimentAnalysis;
            }
        } catch (error) {
            console.error('fetchAnalysisForType Error:', error);
            handleError(error)
            return null
        }
    },

    fetchFastaDatabases: async (): Promise<string[]> => {
        try {
            const response = await generateClient().graphql({query: queries.getFastaDatabases})
            return response.data.getFastaDatabases as unknown as Promise<string[]>
        } catch (error) {
            console.error('fetchFastaDatabases Error:', error);
            handleError(error)
            return []
        }
    },

    fetchYeastTkoStats: async (experiment: string) => {
        try {
            const response = await generateClient().graphql({query: queries.getYeastTkoStats,
                variables: {experiment: experiment}})
            return response.data.getYeastTkoStats
        } catch (error) {
            console.error('fetchYeastTkoStats Error:', error);
            handleError(error)
            return {imputedValue: null}
        }
    },

    fetchOnTheFly: async (experiment: string, fileType: PXPfileType, method: PXPAnalysisMethod) => {
        try {
            const observeDownloadReady = (experiment: string, fileType: string, method: string): Promise<any> => {
                return new Promise((resolve, reject) => {
                    // console.log('CREATING Promise for subscription')
                    const downloadReady = generateClient().graphql({query: completedDownloadConversion})
                    const subscription = downloadReady.subscribe({
                        next: function (result) {
                            const data = result.data.completedDownloadConversion
                            if (data.experiment === experiment && data.fileType === fileType && data.method === method) {
                                subscription.unsubscribe()
                                resolve(data)
                            }
                        },
                        error(err) {
                            reject(err)
                        },
                        complete() {
                            console.log('OBSERVATION COMPLETE')
                        }
                    })
                })
            }
            const promise = observeDownloadReady(experiment, fileType, method)
            // console.log('fixing to call createConvertedExperimentDownload')
            const response = await generateClient().graphql({query: createConvertedExperimentDownload,
                variables: {experiment: experiment, fileType: fileType, method: method}})
            if (response.data?.createConvertedExperimentDownload !== 'success') {
                console.log(`Unexpected response from createConvertedExperimentDownload: ${JSON.stringify(response)}`)
            }
            return promise
        } catch (error) {
            console.error('fetchOnTheFly Error:', error);
            handleError(error)
            return null
        }
    },
    fetchContrasts: async (experiment: string): Promise<string[]> => {
        try {
            const response = await generateClient().graphql({query: getExperimentContrasts,
                variables: {experimentName: experiment}})
            const data: (string|null)[] | null | undefined = response.data.getExperimentContrasts
            if (data && data[0]) {
                return data as unknown as string[]
            }
        } catch (error) {
            console.error('fetchContrasts Error:', error);
            handleError(error)
        }
        return []
    }
}

export default  details