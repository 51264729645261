/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getYeastTkoStats = /* GraphQL */ `query GetYeastTkoStats($experiment: String!) {
  getYeastTkoStats(experiment: $experiment) {
    imputedValue
    ifiStatistics {
      TMT_channel
      num_PSMs
      num_imputed_values
      median
      mean
      num_outliers
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetYeastTkoStatsQueryVariables,
  APITypes.GetYeastTkoStatsQuery
>;
export const getExperimentStatistics = /* GraphQL */ `query GetExperimentStatistics(
  $recordType: String!
  $filters: ExperimentFilterInput!
) {
  getExperimentStatistics(recordType: $recordType, filters: $filters) {
    items {
      experiment
      date
      starting_psm_count
      starting_protein_count
      high_isolation_interfence
      low_signal_to_noise
      total_number_of_rows_removed
      num_proteins_remaining
      instrument
      injectionNumber
      pd_psm_file
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExperimentStatisticsQueryVariables,
  APITypes.GetExperimentStatisticsQuery
>;
export const getExperimentStatistic = /* GraphQL */ `query GetExperimentStatistic($experiment: String!, $recordType: String!) {
  getExperimentStatistic(experiment: $experiment, recordType: $recordType) {
    experiment
    date
    starting_psm_count
    high_interference_psm_count
    low_signal_noise_psm_count
    missing_channel_psm_count
    under_sps_mass_count
    identifying_node_count
    confidence_count
    ambiguity_count
    number_of_proteins_count
    total_psm_removed
    starting_protein_count
    remaining_protein_count
    unique_peptides_filter_count
    pd_psm_file
    instrument
    injectionNumber
    unmodified_peptides_counter
    modified_peptides_counter
    removed_by_sum_PSMs_filters_count
    removed_by_MVI_TMP_filters_count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExperimentStatisticQueryVariables,
  APITypes.GetExperimentStatisticQuery
>;
export const getFlashStats = /* GraphQL */ `query GetFlashStats($experiment: String!, $recordType: String!) {
  getFlashStats(experiment: $experiment, recordType: $recordType) {
    experiment
    record_value {
      method_a_filters_count {
        number_PCM_rescued_due_to_transfer_q_value
        number_peptides_rescued_due_to_transfer_q_value
        total_PCM_count_remaining
        total_peptide_count_remaining
        total_protein_count_remaining
        unique_PCM_count_remaining
        unique_peptide_count_remaining
        unique_protein_count_remaining
        __typename
      }
      starting_total_PCM_count
      starting_total_peptide_count
      starting_total_protein_count
      starting_unique_PCM_count
      starting_unique_peptide_count
      starting_unique_protein_count
      total_number_proteotypic_PCM
      total_number_proteotypic_peptides
      total_number_proteotypic_proteins
      unique_number_proteotypic_PCM
      unique_number_proteotypic_peptides
      unique_number_proteotypic_proteins
      missing_MSF_files
      issues_parsing_MSF_files
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFlashStatsQueryVariables,
  APITypes.GetFlashStatsQuery
>;
export const getExperimentList = /* GraphQL */ `query GetExperimentList(
  $recordType: String!
  $filters: ExperimentFilterInput!
) {
  getExperimentList(recordType: $recordType, filters: $filters) {
    items {
      experiment
      status
      history {
        status
        date
        __typename
      }
      injectionNumber
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExperimentListQueryVariables,
  APITypes.GetExperimentListQuery
>;
export const getMostRecentNonQCDesigns = /* GraphQL */ `query GetMostRecentNonQCDesigns($limit: Int, $nextToken: String) {
  getMostRecentNonQCDesigns(limit: $limit, nextToken: $nextToken) {
    items {
      experiment
      creation_date
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMostRecentNonQCDesignsQueryVariables,
  APITypes.GetMostRecentNonQCDesignsQuery
>;
export const getExperimentContrasts = /* GraphQL */ `query GetExperimentContrasts($experimentName: String!) {
  getExperimentContrasts(experimentName: $experimentName)
}
` as GeneratedQuery<
  APITypes.GetExperimentContrastsQueryVariables,
  APITypes.GetExperimentContrastsQuery
>;
export const getExperimentDesign = /* GraphQL */ `query GetExperimentDesign($experiment: String!) {
  getExperimentDesign(experiment: $experiment) {
    conditions {
      channel
      cells
      treatmentTime
      description

      ... on Condition {
        genotype
        treatmentTimeUnit
        type
        taxon
        compounds {
          batch
          compound
          concentration {
            concentration
            unit
            __typename
          }
          __typename
        }
      }
      ... on TMTCondition {
        compound
        cddBatchId
        concentration {
          concentration
          unit
          __typename
        }
      }
    }
    type
    tmt_lot
    projectName
    responsible
    collaborator
    benchling
    noOfSamples
    bufferVolume
    schema_version
    experiment_folder
    scinamicPlateMap
    cellCultureBarcode
    peptideBarcode
    flashWarningsAcknowledged
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExperimentDesignQueryVariables,
  APITypes.GetExperimentDesignQuery
>;
export const getExperimentStatus = /* GraphQL */ `query GetExperimentStatus($experiment: String!, $recordType: String!) {
  getExperimentStatus(experiment: $experiment, recordType: $recordType) {
    experiment
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExperimentStatusQueryVariables,
  APITypes.GetExperimentStatusQuery
>;
export const getExperimentAnalysis = /* GraphQL */ `query GetExperimentAnalysis($experiment: String!) {
  getExperimentAnalysis(experiment: $experiment) {
    database
    instrument
    pd_version
    pd_psm_file
    psm_threshold
    isolation_interference_cutoff
    average_reporter_sn_cutoff
    missing_channel_threshold
    sps_mass_matches_cutoff
    ignore_sps_mass_matches
    skip_normalization
    psm_ambiguity
    identifying_node
    experiment_folder
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExperimentAnalysisQueryVariables,
  APITypes.GetExperimentAnalysisQuery
>;
export const getFlashAnalysis = /* GraphQL */ `query GetFlashAnalysis($experiment: String!) {
  getFlashAnalysis(experiment: $experiment) {
    experiment
    record_value {
      samples_selected_by_user
      transfer_qValue_cutoff
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFlashAnalysisQueryVariables,
  APITypes.GetFlashAnalysisQuery
>;
export const getUploadUrl = /* GraphQL */ `query GetUploadUrl($key: String!) {
  getUploadUrl(key: $key) {
    url
    key
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUploadUrlQueryVariables,
  APITypes.GetUploadUrlQuery
>;
export const getExperimentDownloadUrl = /* GraphQL */ `query GetExperimentDownloadUrl(
  $keys: [String]!
  $experimentDownload: ExperimentDownload!
) {
  getExperimentDownloadUrl(
    keys: $keys
    experimentDownload: $experimentDownload
  ) {
    url
    key
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExperimentDownloadUrlQueryVariables,
  APITypes.GetExperimentDownloadUrlQuery
>;
export const getLabelEfficiencyUploadUrl = /* GraphQL */ `query GetLabelEfficiencyUploadUrl($key: String!) {
  getLabelEfficiencyUploadUrl(key: $key) {
    url
    key
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLabelEfficiencyUploadUrlQueryVariables,
  APITypes.GetLabelEfficiencyUploadUrlQuery
>;
export const getLabelEfficiency = /* GraphQL */ `query GetLabelEfficiency(
  $psmFile: String!
  $pdVersion: PDVersion
  $tmt: TMT!
  $experimentName: String
  $experimentType: ExperimentType
) {
  getLabelEfficiency(
    psmFile: $psmFile
    pdVersion: $pdVersion
    tmt: $tmt
    experimentName: $experimentName
    experimentType: $experimentType
  ) {
    totalPeptides
    labeledNTermPeptides
    totalKTermPeptides
    labelKTermPeptides
    boxPlot {
      min
      max
      bandDomain
      stats {
        fiveNums
        iqr
        step
        fences {
          start
          end
          __typename
        }
        boxes {
          start
          end
          __typename
        }
        whiskers {
          start
          end
          __typename
        }
        points {
          value
          datum
          outlier
          farout
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLabelEfficiencyQueryVariables,
  APITypes.GetLabelEfficiencyQuery
>;
export const getExperimentPcaData = /* GraphQL */ `query GetExperimentPcaData($experiment: String!, $recordType: String!) {
  getExperimentPcaData(experiment: $experiment, recordType: $recordType) {
    id
    data {
      x
      y
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExperimentPcaDataQueryVariables,
  APITypes.GetExperimentPcaDataQuery
>;
export const getFastaDatabases = /* GraphQL */ `query GetFastaDatabases {
  getFastaDatabases
}
` as GeneratedQuery<
  APITypes.GetFastaDatabasesQueryVariables,
  APITypes.GetFastaDatabasesQuery
>;
export const getFastaUploadUrl = /* GraphQL */ `query GetFastaUploadUrl($key: String!) {
  getFastaUploadUrl(key: $key) {
    url
    key
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFastaUploadUrlQueryVariables,
  APITypes.GetFastaUploadUrlQuery
>;
export const getStats = /* GraphQL */ `query GetStats($experiment: String!, $recordType: String!) {
  getStats(experiment: $experiment, recordType: $recordType) {
    snLog10Histogram {
      min
      max
      binWidth
      binCounts
      __typename
    }
    channelIntensityStats {
      min
      max
      bandDomain
      stats {
        fiveNums
        iqr
        step
        fences {
          start
          end
          __typename
        }
        boxes {
          start
          end
          __typename
        }
        whiskers {
          start
          end
          __typename
        }
        points {
          value
          datum
          outlier
          farout
          __typename
        }
        __typename
      }
      __typename
    }
    peptideOverlaps {
      fraction
      keys
      counts
      __typename
    }
    fractionCounts {
      fraction
      psm
      qpsm
      __typename
    }
    psmPrecursorIntensities {
      min
      max
      binWidth
      binCounts
      __typename
    }
    qpsmPrecursorIntensities {
      min
      max
      binWidth
      binCounts
      __typename
    }
    massErrorHistogram {
      min
      max
      binWidth
      binCounts
      __typename
    }
    sampleControlRatios {
      min
      max
      bandDomain
      stats {
        fiveNums
        iqr
        step
        fences {
          start
          end
          __typename
        }
        boxes {
          start
          end
          __typename
        }
        whiskers {
          start
          end
          __typename
        }
        points {
          value
          datum
          outlier
          farout
          __typename
        }
        __typename
      }
      __typename
    }
    pcaData {
      id
      data {
        x
        y
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetStatsQueryVariables, APITypes.GetStatsQuery>;
export const dispatchPostgresFunction = /* GraphQL */ `query DispatchPostgresFunction($input: DispatchInput!) {
  dispatchPostgresFunction(input: $input)
}
` as GeneratedQuery<
  APITypes.DispatchPostgresFunctionQueryVariables,
  APITypes.DispatchPostgresFunctionQuery
>;
export const getYeastReferenceExperiment = /* GraphQL */ `query GetYeastReferenceExperiment($instrument: String!) {
  getYeastReferenceExperiment(instrument: $instrument) {
    instrument
    experiment
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetYeastReferenceExperimentQueryVariables,
  APITypes.GetYeastReferenceExperimentQuery
>;
export const getLcmsStatus = /* GraphQL */ `query GetLcmsStatus($filters: LcmsFilters) {
  getLcmsStatus(filters: $filters) {
    items {
      experiment
      exp_type
      exp_status
      planned_date
      processed_date
      published_date
      responsible
      collaborator
      projectName
      benchling
      lcms_start
      lcms_end
      instrument
      experiment_folder
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLcmsStatusQueryVariables,
  APITypes.GetLcmsStatusQuery
>;
export const getFlashExperimentUploadUrl = /* GraphQL */ `query GetFlashExperimentUploadUrl($key: String) {
  getFlashExperimentUploadUrl(key: $key) {
    url
    key
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFlashExperimentUploadUrlQueryVariables,
  APITypes.GetFlashExperimentUploadUrlQuery
>;
export const getBarcodeInfo = /* GraphQL */ `query GetBarcodeInfo($peptideBarcode: String) {
  getBarcodeInfo(peptideBarcode: $peptideBarcode)
}
` as GeneratedQuery<
  APITypes.GetBarcodeInfoQueryVariables,
  APITypes.GetBarcodeInfoQuery
>;
export const getProcessableFlashSamples = /* GraphQL */ `query GetProcessableFlashSamples($experiment: String!) {
  getProcessableFlashSamples(experiment: $experiment) {
    fileName
    sampleId
    position
    injVol
    L1Replicate
    L2Compound
    L3CUM
    L4Time
    L5CellLine
    comment
    sampleName
    fileSize
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProcessableFlashSamplesQueryVariables,
  APITypes.GetProcessableFlashSamplesQuery
>;
export const keyExists = /* GraphQL */ `query KeyExists($key: String, $bucketType: ExperimentDownload) {
  keyExists(key: $key, bucketType: $bucketType)
}
` as GeneratedQuery<APITypes.KeyExistsQueryVariables, APITypes.KeyExistsQuery>;
export const getResource = /* GraphQL */ `query GetResource($resource: String!) {
  getResource(resource: $resource) {
    resource_name
    resource
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetResourceQueryVariables,
  APITypes.GetResourceQuery
>;
