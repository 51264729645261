/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const validateFastaUpload = /* GraphQL */ `mutation ValidateFastaUpload($input: ValidateFastaInput) {
  validateFastaUpload(input: $input)
}
` as GeneratedMutation<
  APITypes.ValidateFastaUploadMutationVariables,
  APITypes.ValidateFastaUploadMutation
>;
export const startAnalysis = /* GraphQL */ `mutation StartAnalysis($input: RunAnalysisInput!) {
  startAnalysis(input: $input) {
    status
    experimentName
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.StartAnalysisMutationVariables,
  APITypes.StartAnalysisMutation
>;
export const startFlashAnalysis = /* GraphQL */ `mutation StartFlashAnalysis($input: FlashAnalysisInput!) {
  startFlashAnalysis(input: $input) {
    status
    experimentName
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.StartFlashAnalysisMutationVariables,
  APITypes.StartFlashAnalysisMutation
>;
export const startDiaAnalysis = /* GraphQL */ `mutation StartDiaAnalysis($input: DiaAnalysisInput!) {
  startDiaAnalysis(input: $input) {
    status
    experimentName
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.StartDiaAnalysisMutationVariables,
  APITypes.StartDiaAnalysisMutation
>;
export const analysisStepComplete = /* GraphQL */ `mutation AnalysisStepComplete(
  $status: String!
  $experimentName: String!
  $message: String
) {
  analysisStepComplete(
    status: $status
    experimentName: $experimentName
    message: $message
  ) {
    status
    experimentName
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AnalysisStepCompleteMutationVariables,
  APITypes.AnalysisStepCompleteMutation
>;
export const publishExperiment = /* GraphQL */ `mutation PublishExperiment($input: PublishExperimentInput!) {
  publishExperiment(input: $input) {
    status
    experimentName
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.PublishExperimentMutationVariables,
  APITypes.PublishExperimentMutation
>;
export const publishComplete = /* GraphQL */ `mutation PublishComplete($input: PublishCompleteInput) {
  publishComplete(input: $input) {
    status
    experimentName
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.PublishCompleteMutationVariables,
  APITypes.PublishCompleteMutation
>;
export const deleteExperiment = /* GraphQL */ `mutation DeleteExperiment($experimentName: String!, $experimentType: String!) {
  deleteExperiment(
    experimentName: $experimentName
    experimentType: $experimentType
  )
}
` as GeneratedMutation<
  APITypes.DeleteExperimentMutationVariables,
  APITypes.DeleteExperimentMutation
>;
export const revertExperiment = /* GraphQL */ `mutation RevertExperiment($experimentName: String!, $experimentType: String!) {
  revertExperiment(
    experimentName: $experimentName
    experimentType: $experimentType
  )
}
` as GeneratedMutation<
  APITypes.RevertExperimentMutationVariables,
  APITypes.RevertExperimentMutation
>;
export const labelCheckPcaAnalysis = /* GraphQL */ `mutation LabelCheckPcaAnalysis($input: LabelEfficiencyPcaInput) {
  labelCheckPcaAnalysis(input: $input) {
    experimentName
    plot {
      id
      data {
        x
        y
        __typename
      }
      __typename
    }
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.LabelCheckPcaAnalysisMutationVariables,
  APITypes.LabelCheckPcaAnalysisMutation
>;
export const uploadPlannedExperiment = /* GraphQL */ `mutation UploadPlannedExperiment($input: [ExperimentTableInput]!) {
  uploadPlannedExperiment(input: $input) {
    experiment
    record_type
    schema_version
    record_value {
      type
      projectName
      conditions {
        channel
        cells
        genotype
        treatmentTime
        treatmentTimeUnit
        type
        taxon
        description
        compounds {
          batch
          compound
          concentration {
            concentration
            unit
            __typename
          }
          __typename
        }
        __typename
      }
      responsible
      collaborator
      benchling
      noOfSamples
      __typename
    }
    edit_date
    creation_date
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UploadPlannedExperimentMutationVariables,
  APITypes.UploadPlannedExperimentMutation
>;
export const getNextExperimentNumber = /* GraphQL */ `mutation GetNextExperimentNumber($year: Int!) {
  getNextExperimentNumber(year: $year) {
    year
    experiment_counter
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GetNextExperimentNumberMutationVariables,
  APITypes.GetNextExperimentNumberMutation
>;
export const updateLcmsInfo = /* GraphQL */ `mutation UpdateLcmsInfo(
  $experiment: String!
  $start: String!
  $end: String!
  $instrument: String!
) {
  updateLcmsInfo(
    experiment: $experiment
    start: $start
    end: $end
    instrument: $instrument
  ) {
    experiment
    exp_type
    exp_status
    planned_date
    processed_date
    published_date
    responsible
    collaborator
    projectName
    benchling
    lcms_start
    lcms_end
    instrument
    experiment_folder
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLcmsInfoMutationVariables,
  APITypes.UpdateLcmsInfoMutation
>;
export const completeExperiment = /* GraphQL */ `mutation CompleteExperiment(
  $experimentName: String!
  $experimentType: String!
  $status: String!
) {
  completeExperiment(
    experimentName: $experimentName
    experimentType: $experimentType
    status: $status
  )
}
` as GeneratedMutation<
  APITypes.CompleteExperimentMutationVariables,
  APITypes.CompleteExperimentMutation
>;
export const createConvertedExperimentDownload = /* GraphQL */ `mutation CreateConvertedExperimentDownload(
  $experiment: String!
  $fileType: PXPfileType!
  $method: PXPAnalysisMethod!
) {
  createConvertedExperimentDownload(
    experiment: $experiment
    fileType: $fileType
    method: $method
  )
}
` as GeneratedMutation<
  APITypes.CreateConvertedExperimentDownloadMutationVariables,
  APITypes.CreateConvertedExperimentDownloadMutation
>;
export const getConvertedExperimentDownloadUrl = /* GraphQL */ `mutation GetConvertedExperimentDownloadUrl($input: GeneratedFileDownload) {
  getConvertedExperimentDownloadUrl(input: $input) {
    experiment
    fileType
    method
    url
    key
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GetConvertedExperimentDownloadUrlMutationVariables,
  APITypes.GetConvertedExperimentDownloadUrlMutation
>;
export const updateBarcodePair = /* GraphQL */ `mutation UpdateBarcodePair($parent: String!, $child: String!) {
  updateBarcodePair(parent: $parent, child: $child) {
    keys
    cancellationReasons
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBarcodePairMutationVariables,
  APITypes.UpdateBarcodePairMutation
>;
export const deleteBarcodePair = /* GraphQL */ `mutation DeleteBarcodePair($parent: String!, $child: String!) {
  deleteBarcodePair(parent: $parent, child: $child) {
    keys
    cancellationReasons
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBarcodePairMutationVariables,
  APITypes.DeleteBarcodePairMutation
>;
export const processFlashSampleList = /* GraphQL */ `mutation ProcessFlashSampleList($input: FlashSampleListInput) {
  processFlashSampleList(input: $input) {
    status
    experimentName
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ProcessFlashSampleListMutationVariables,
  APITypes.ProcessFlashSampleListMutation
>;
export const acknowledgeFlashWarnings = /* GraphQL */ `mutation AcknowledgeFlashWarnings($experimentName: String!, $user: String!) {
  acknowledgeFlashWarnings(experimentName: $experimentName, user: $user) {
    experiment
    record_type
    record_value {
      conditions {
        channel
        cells
        treatmentTime
        description

        ... on Condition {
          genotype
          treatmentTimeUnit
          type
          taxon
          compounds {
            batch
            compound
            concentration {
              concentration
              unit
              __typename
            }
            __typename
          }
        }
        ... on TMTCondition {
          compound
          cddBatchId
          concentration {
            concentration
            unit
            __typename
          }
        }
      }
      type
      tmt_lot
      projectName
      responsible
      collaborator
      benchling
      noOfSamples
      bufferVolume
      schema_version
      experiment_folder
      scinamicPlateMap
      cellCultureBarcode
      peptideBarcode
      flashWarningsAcknowledged
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AcknowledgeFlashWarningsMutationVariables,
  APITypes.AcknowledgeFlashWarningsMutation
>;
export const updateFlashSampleList = /* GraphQL */ `mutation UpdateFlashSampleList(
  $experimentName: String!
  $experimentType: String!
) {
  updateFlashSampleList(
    experimentName: $experimentName
    experimentType: $experimentType
  )
}
` as GeneratedMutation<
  APITypes.UpdateFlashSampleListMutationVariables,
  APITypes.UpdateFlashSampleListMutation
>;
