import {useEffect, useState} from 'react';
import {Box, Button, CardHeader, Grid, Typography} from '@mui/material';
import { grey } from '@mui/material/colors';
import {ExperimentDownload} from '../../../API';
import * as d3 from 'd3-fetch';
import {ScatterPlotCanvas, ScatterPlotRawSerie, ScatterPlotDatum} from '@nivo/scatterplot';
import {
    StyledListPlotTip, StyledPlotTipPaper, StyledPlotLabelCard
} from '../../Dashboard/ExperimentsPanel/StyledComponents/';
import details from '../../../services/details';

const boxBorderColor = grey[200];
interface PlotData extends ScatterPlotDatum {
    acc: string;
    peptides: string;
    negativeLogTenAdjustedPValue: number;
}
interface VolcanoPlotProps {
    experiment: string;
    contrast: string;
    twoColumn: boolean;
}
export default function VolcanoPlot({experiment, contrast, twoColumn }: VolcanoPlotProps) {
    const [data, setData] = useState<ScatterPlotRawSerie<any>[]>([]);
    const [measurementIndex, setMeasurementIndex] = useState(1);
    const fixedSize = twoColumn ? 500 : 600;
    useEffect(() => {
        async function fetchData(key: string) {
            try {
                const urls = await details.fetchExperimentDownloadUrl([key], ExperimentDownload.LIMMA);
                if (urls) {
                    const url = urls[0]!.url
                    const rawData = await d3.csv(url);
                    // console.log(`RAW data length ${rawData.length}`, rawData)
                    const mydata: PlotData[] = rawData.filter((d: any) => d.logFC !== 'NA')
                        .map((d: any) => {
                            return {
                                acc: d['']!,
                                peptides: d['n.peptides']!,
                                x: parseFloat(d.logFC!),
                                y: measurementIndex === 1 ? -Math.log10(parseFloat(d['P.Value']!))
                                    : -Math.log10(parseFloat(d['adj.P.Val']!)),
                                negativeLogTenAdjustedPValue: -Math.log10(parseFloat(d['adj.P.Val']!))
                            }
                        })
                    // console.log(`Got data ${mydata.length}`, mydata)
                    setData([{id: 1, data: mydata}]);
                } else {
                    setData([])
                }
            } catch (error) {
                console.error(`fetchData for key ${key} error`, error)
                setData([]);
            }
        }
        fetchData(`${experiment}/${contrast}_PROTEIN.csv`);
    },[experiment, contrast, measurementIndex]);

    function doTip(node: any) {
        return (
        <StyledPlotTipPaper elevation={1}>
            <StyledListPlotTip component="ul">
                <Typography variant="body1" component="li">
                    <strong>Accession:{' '}</strong>
                    {node.data.acc}
                </Typography>
                <Typography variant="body1" component="li">
                    <strong>Uniq. Peptides:{' '}</strong>
                    {node.data.peptides}
                </Typography>
                <Typography variant="body1" component="li">
                    <strong>log2(FC):{' '}</strong>
                    {node.data.x.toFixed(2)}
                </Typography>
                <Typography variant="body1" component="li">
                    <strong>{measurementIndex === 1 ? '-log10(p-value)' : '-log10(adj. p-value)'}:{' '}</strong>
                    {node.data.y.toFixed(2)}
                </Typography>
            </StyledListPlotTip>
        </StyledPlotTipPaper>
        );
    }

    function RenderPlot() {

        let maxNegativeLogTenAdjustedPValue = 0;
        for (let i = 0; i < data[0].data.length -1; i++)
        {
            if (data[0].data[i].negativeLogTenAdjustedPValue > maxNegativeLogTenAdjustedPValue) {
                maxNegativeLogTenAdjustedPValue = data[0].data[i].negativeLogTenAdjustedPValue ;
            }
        } 
        return (
            // @ts-ignore
            <ScatterPlotCanvas
                width={fixedSize}
                height={fixedSize}
                data={data}
                margin={{top:15, right:10, bottom: 45, left: 45}}
                xScale={{type: 'linear', min: 'auto', max: 'auto'}}
                xFormat=">-.2f"
                yScale={{type: 'linear', min: 0, max: 'auto'}}
                yFormat=">-.2f"
                nodeSize={4}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "log2(FC)",
                    legendPosition: 'middle',
                    legendOffset: 36
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: (measurementIndex === 1 ? '-log10(p-value)' : '-log10(adj. p-value)'),  
                    legendPosition: "middle",
                    legendOffset: -30
                }}
                // if maxNegativeLogTenAdjustedPValue < 2 set the color of the proteins to red
                colors= {{scheme: (maxNegativeLogTenAdjustedPValue < 2 ? 'set1' : 'nivo')}}
                legends={[]}
                tooltip={({ node }) => {
                    return doTip(node)
                }}
            />
        )
    }
    const pair = contrast.split(/ - /);
    // @ts-ignore
    return (
        <Grid item sm={12} md={12} lg={twoColumn ? 6 : 12}>
            <Box p={1} border={`1px solid ${boxBorderColor}`}>
                <Box display="flex" alignItems="center" mb={2}>
                    <StyledPlotLabelCard variant="outlined">
                        <CardHeader
                            title={pair[0].split(/_/).join(' | ')}
                        >
                        </CardHeader>
                    </StyledPlotLabelCard>
                    <StyledPlotLabelCard variant="outlined">
                        <CardHeader
                            title={pair[1].split(/_/).join(' | ')}
                        >
                        </CardHeader>
                    </StyledPlotLabelCard>
                    <Button variant="outlined" size="small" 
                    sx={{mx: 1}} onClick={() => {setMeasurementIndex(measurementIndex === 1 ? 2 : 1)}}
                >
                    {measurementIndex === 1 ? 'use adjusted p-values' : 'use p-values'}
                </Button>
                </Box>
                { data.length > 0 ?
                      <RenderPlot />
                : <Box height={fixedSize} width={fixedSize} p={3} bgcolor="grey.50">
                        loading...
                </Box>}
            </Box>
        </Grid>
    );
}