import {OptionsObject, useSnackbar} from 'notistack';
import {useEffect} from 'react';
import {Hub} from 'aws-amplify/utils'

/**
 * This used to intercept all axios calls to look for errors in the graphql response.
 * In Amplify v6, AWS completely changed the way graphql errors are handled, and
 * axios was removed as an amplify dependency anyway. So we have a completely new
 * way of presenting the errors with notistack.
 * @constructor
 */
export const AxiosInterceptor = () => {
    const {enqueueSnackbar} = useSnackbar();
    const errorOptions: OptionsObject = {
        variant: 'error',
        persist: true
    };

    useEffect(() => {
        const hubListenerCxl = Hub.listen('NotistackErrorChannel', async ({payload: { event, data }}) => {
            // console.log(`Error event ${event} with data `, data)
            // @ts-ignore
            let msg = `System Error: ${data.error}`;
            enqueueSnackbar(msg, errorOptions);
        })
        return () => {
            hubListenerCxl()
        }
        // eslint-disable-next-line
    }, [])
    return null;
};