import {generateClient} from 'aws-amplify/api';
import {getNextExperimentNumber, uploadPlannedExperiment} from '../graphql/mutations';
import {getExperimentDownloadUrl, getFlashExperimentUploadUrl, getMostRecentNonQCDesigns} from '../graphql/queries';
import {ExperimentDownload} from '../API';
import {handleError, paddedNumber} from '../util/util';


const designs = {
    fetchNonQcExperiments: async (): Promise<any[]> => {
        try {
            const result = await generateClient().graphql({query: getMostRecentNonQCDesigns, variables:
                {limit: 1000}});
            if (result.data.getMostRecentNonQCDesigns?.items) {
                // console.log(`I found ${result.data.getMostRecentNonQCDesigns?.items?.length} designs`)
                return result.data.getMostRecentNonQCDesigns?.items;
            }
        } catch (error) {
            console.error("fetchNonQcExperiments Error", error);
            handleError(error)
            return [];
        }
        return []
    },
    createName: async (type: ExperimentType): Promise<string> => {
        try {
            const currentYear = new Date().getFullYear();
            const nextResult = await generateClient().graphql({query: getNextExperimentNumber,
                variables: {year: currentYear}})
            const nextNumber: number = nextResult.data.getNextExperimentNumber.experiment_counter as unknown as number
            return `PX_${type.code}_${paddedNumber(nextNumber, 5)}_${currentYear}`;
        } catch (error) {
            console.error('createName Error:', error);
            handleError(error)
            return ''
        }
    },
    uploadPlanned: async (designData: any[]): Promise<any> => {
        try {
            return await generateClient().graphql({query: uploadPlannedExperiment, variables: {input: designData}})
        } catch (err) {
            console.error('uploadPlanned Error', err);
            handleError(err)
            return undefined
        }
    },
    editableByUser: (experimentDesign: any, currentUser: string | undefined) => {
        return true
        // const responsibleUser = experimentDesign?.responsible
        // const collaboratingUser = experimentDesign?.collaborator
        // // Double equals used to catch both null and undefined outcomes
        // if (responsibleUser == null && collaboratingUser == null) {
        //     return true
        // }
        // const currentLastName = currentUser?.substring(1).toLowerCase();
        // return responsibleUser?.toLowerCase().includes(currentLastName as string) ||
        //     collaboratingUser?.toLowerCase().includes(currentLastName as string);
    },
    getFlashUploadUrl: async (key: string) => {
        try {
            const result = await generateClient().graphql({query: getFlashExperimentUploadUrl,
                variables: {key: key}})
            return result.data.getFlashExperimentUploadUrl?.url
        } catch (error) {
            console.error('getFlashUploadUrl Error:', error);
            handleError(error)
            return ''
        }
    },
    doFlashUpload: async (url: string, file: File) => {
        await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'binary/octet-stream'
            },
            body: file
        })
    },
    doFlashDownload: async (key: string, prefix: string) => {
        try {
            const result = await generateClient().graphql({query: getExperimentDownloadUrl,
                variables: { keys: [key], experimentDownload: ExperimentDownload.FLASH}})
            if (result.data.getExperimentDownloadUrl) {
                const url = result.data.getExperimentDownloadUrl[0]
                // We only want the last part of the key
                if (url) {
                    const name = prefix + url.key.split('/').pop();
                    console.log(`MY URL with name ${name}`, url)
                    const a = document.createElement('a')
                    a.href = url.url;
                    a.download = name;
                    a.click()
                }
            }
        } catch (error) {
            console.error('doFlashDownload Error:', error);
            handleError(error)
        }
    }
}

export default designs