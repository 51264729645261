import {useContext, useEffect, useState} from "react";
import {
    Box, Chip, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
    Typography, useTheme
} from '@mui/material/';
import { StyledListBox, StyledListTable } from '../../StyledComponents';
import {Pie} from '@nivo/pie';
import {Bar} from '@nivo/bar';
import useMounted from "../../../../../util/useMounted";
import {ExperimentStatisticsPanelDataCy as espDataCy} from "./ExperimentStatisticsPanel.cy";
import {ExperimentAnalysis, PDVersion} from "src/API";
import { PTM_TYPES } from "src/util/options";
import details from '../../../../../services/details';
import {ExperimentTypeContext} from '../../../../../store/ExperimentTypeContext';

interface ExperimentStatisticPanelProps {
    experimentName: string;
    experimentDesign?: any;
    experimentAnalysis?: ExperimentAnalysis|null;
}

export default function ExperimentStatisticsPanel({experimentName,
                                                      experimentDesign,
                                                      experimentAnalysis}: ExperimentStatisticPanelProps) {
    const isMounted = useMounted();
    const theme = useTheme();
    const [stats, setStats] = useState<any>(null)
    const experimentType = useContext<ExperimentType>(ExperimentTypeContext)

    useEffect(() => {
        const fetchStatistics = async (experimentName: string, statsRecordType: string) => {
            const data: any = await details.fetchStatistics(experimentName, statsRecordType)
            if (isMounted()) {
                setStats(data);
            }
        };
        void fetchStatistics(experimentName, experimentType.statsType)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [experimentName, experimentType.statsType]);

    const renderPsmFile = () => {
        const index = experimentAnalysis?.pd_psm_file.lastIndexOf('/')
        return index && index > 0 ? experimentAnalysis?.pd_psm_file.substring(index + 1) : experimentAnalysis?.pd_psm_file
    }
    
    const chartColors = ['#749AB4', '#FF8042'];
    const unknownMessage = 'unknown';
    const sxChip = {
        ml: 0.5,
        mb: 0.25,
        height: 16,
        fontSize: theme.typography.xSmall2.fontSize,
        fontWeight: 500,
        letterSpacing: '0.02857em',
        '& .MuiChip-label': { pl: 0.75, pr: 0.75}
    } as const;

    return <>
        <StyledListBox component="ul" mt={1.5} mb={4} ml={2}>
            <Typography component="li" data-cy={espDataCy.instrument}>
                <strong>Instrument:{' '}</strong>
                {experimentAnalysis?.instrument}
            </Typography>
            <Typography component="li" data-cy={espDataCy.database}>
                <strong>Database:{' '}</strong>
                {experimentAnalysis?.database ?? unknownMessage}
            </Typography>
            <Typography component="li" data-cy={espDataCy.pdVersion}>
                <strong>PD version:{' '}</strong>
                {experimentAnalysis?.pd_version ?? unknownMessage}
            </Typography>
            <Typography component="li" data-cy={espDataCy.tmtLot}>
                <strong>TMT lot:{' '}</strong>
                {experimentDesign?.tmt_lot ?? unknownMessage}
            </Typography>
            <Typography component="li" data-cy={espDataCy.psmFile}>
                <strong>PSM File:{' '}</strong>
                <Typography component="span" display="block" sx={{ ml: 2 }}>
                {renderPsmFile()}
                </Typography>
                <Typography component="span" display="block"  sx={{ ml: 2 }}>
                {/*Prior to the addition of unique_peptides_filter_count, starting_psm_count did not reflect the actual */}
                {/*total psm count in the file, as we filtered on Confidence, Ambiguity, # Proteins prior to counting */}
                {stats?.unique_peptides_filter_count !== null && ` (${stats?.original.starting_psm_count} rows)`}
                </Typography>
            </Typography>
            <Box component="li" mt={-1} ml={2} width="90%">
                <TableContainer>
                    <StyledListTable sx={{ mb: 1 }}>
                        <colgroup>
                            <col span={1} style={{ width: '30%'}} />
                            <col span={1} style={{ width: '20%'}} />
                            <col span={stats?.mviTmpFilters ? 2 : 1}
                                style={stats?.mviTmpFilters ? { width: '25%'} : { width: '50%'}} />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell>Filter Name</TableCell>
                                <TableCell>Value</TableCell>
                                {stats?.mviTmpFilters && <TableCell data-cy={espDataCy.filterRowsRemoved}>
                                    Rows Removed
                                    <Chip color="primary" size="small" label="MVI TMP" sx={sxChip} />
                                </TableCell>}
                                <TableCell>
                                    Rows Removed <Chip color="default" size="small" label="legacy" sx={sxChip} />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stats?.mviTmpFilters && <TableRow>
                                <TableCell component="th" scope="row" colSpan={2}>
                                    De-duplicated features:
                                </TableCell>
                                <TableCell>{stats.mviTmpFilters["de-duplicating_features"]}</TableCell>
                                <TableCell>-</TableCell>
                            </TableRow>}
                            <TableRow data-cy={espDataCy.missingChannelThreshold}>
                                <TableCell component="th" scope="row">
                                    Allowed number of empty channels:
                                </TableCell>
                                <TableCell>
                                {experimentAnalysis?.missing_channel_threshold ?? unknownMessage}
                                </TableCell>
                                {stats?.mviTmpFilters && <TableCell>
                                    {stats.mviTmpFilters.missing_channels} rows {stats?.calculatePercentage(stats.mviTmpFilters.missing_channels)}
                                </TableCell>}
                                <TableCell>
                                    {stats?.legacyFilters.missing_channels} rows {stats?.calculatePercentage(stats?.legacyFilters.missing_channels)}
                                </TableCell>
                            </TableRow>
                            <TableRow data-cy={espDataCy.isolationInterference}>
                                <TableCell component="th" scope="row">
                                    Isolation interference:
                                </TableCell>
                                <TableCell>
                                    {experimentAnalysis?.isolation_interference_cutoff ?? unknownMessage}
                                </TableCell>
                                {stats?.mviTmpFilters && <TableCell>
                                    {stats.mviTmpFilters.high_isolation_interfence} rows {stats?.calculatePercentage(stats.mviTmpFilters.high_isolation_interfence)}
                                </TableCell>}
                                <TableCell>
                                    {stats?.legacyFilters.high_isolation_interfence} rows {stats?.calculatePercentage(stats?.legacyFilters.high_isolation_interfence)}
                                </TableCell>
                            </TableRow>
                            <TableRow data-cy={espDataCy.averageReporterSn}>
                                <TableCell component="th" scope="row">
                                    Average reporter signal to noise:
                                </TableCell>
                                <TableCell>
                                    {experimentAnalysis?.average_reporter_sn_cutoff ?? unknownMessage}
                                </TableCell>
                                {stats?.mviTmpFilters && <TableCell>
                                    {stats?.mviTmpFilters.low_signal_to_noise} rows {stats?.calculatePercentage(stats?.mviTmpFilters.low_signal_to_noise)}
                                </TableCell>}
                                <TableCell>
                                    {stats?.legacyFilters.low_signal_to_noise} rows {stats?.calculatePercentage(stats?.legacyFilters.low_signal_to_noise)}
                                </TableCell>
                            </TableRow>
                            <TableRow data-cy={espDataCy.spsMassMatchesCutoff}>
                                <TableCell component="th" scope="row">
                                    SPS mass matches:
                                </TableCell>
                                <TableCell>
                                    {experimentAnalysis?.sps_mass_matches_cutoff ?? unknownMessage}
                                </TableCell>
                                {stats?.mviTmpFilters && <TableCell>
                                    {stats?.mviTmpFilters.low_SPS_mass_matches !== null &&
                                        `${stats?.mviTmpFilters.low_SPS_mass_matches} rows ${stats?.calculatePercentage(stats?.mviTmpFilters.low_SPS_mass_matches)}`
                                    }
                                </TableCell>}
                                <TableCell>
                                    {experimentAnalysis?.sps_mass_matches_cutoff !== null &&
                                        `${stats?.legacyFilters.low_SPS_mass_matches} rows ${stats?.calculatePercentage(stats?.legacyFilters.low_SPS_mass_matches)}`
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow data-cy={espDataCy.ignoreSpsMassMatches}>
                                <TableCell component="th" scope="row">
                                    Ignore SPS mass matches:
                                </TableCell>
                                <TableCell>
                                    {experimentAnalysis?.ignore_sps_mass_matches ? "yes" : "no"}
                                </TableCell>
                                {stats?.mviTmpFilters && <TableCell>&nbsp;</TableCell>}
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                            {(experimentAnalysis?.pd_version === PDVersion.PD_3_0 || experimentAnalysis?.pd_version === PDVersion.PD_3_1)  ? 
                            <TableRow data-cy={espDataCy.identifyingNode}>
                                <TableCell component="th" scope="row">
                                    Identifying Node:
                                </TableCell>
                                <TableCell>
                                    {experimentAnalysis?.identifying_node?.join(', ')}
                                </TableCell>
                                {stats?.mviTmpFilters && <TableCell>
                                    {stats?.mviTmpFilters["identifying node"]} rows {stats?.calculatePercentage(stats?.mviTmpFilters["identifying node"])}
                                </TableCell>}
                                <TableCell>
                                    {stats?.legacyFilters["identifying node"]} rows {stats?.calculatePercentage(stats?.legacyFilters["identifying node"])}
                                </TableCell>
                            </TableRow>
                            : null }
                            <TableRow data-cy={espDataCy.psmAmbiguity}>
                                <TableCell component="th" scope="row">
                                    PSM Ambiguity:
                                </TableCell>
                                <TableCell>
                                    {experimentAnalysis?.psm_ambiguity ? experimentAnalysis?.psm_ambiguity.join(', ') : 'Unambiguous'}
                                </TableCell>
                                {stats?.mviTmpFilters && <TableCell>
                                    {stats?.mviTmpFilters.PSM_ambiguity !== null &&
                                        `${stats?.mviTmpFilters.PSM_ambiguity} rows ${stats?.calculatePercentage(stats?.mviTmpFilters.PSM_ambiguity)}`
                                    }
                                </TableCell>}
                                <TableCell>
                                    {stats?.legacyFilters.PSM_ambiguity !== null &&
                                        `${stats?.legacyFilters.PSM_ambiguity} rows ${stats?.calculatePercentage(stats?.legacyFilters.PSM_ambiguity)}`
                                    }
                                </TableCell>
                            </TableRow>
                            {(stats?.mviTmpFilters?.confidence !== null || stats?.legacyFilters?.confidence) &&
                            <TableRow data-cy={espDataCy.confidence}>
                                <TableCell component="th" scope="row">
                                    Confidence:
                                </TableCell>
                                <TableCell>High</TableCell>
                                {stats?.mviTmpFilters && <TableCell>
                                    {stats?.mviTmpFilters.confidence} rows {stats?.calculatePercentage(stats?.mviTmpFilters.confidence_count)}
                                </TableCell>}
                                <TableCell>
                                    {stats?.legacyFilters.confidence} rows {stats?.calculatePercentage(stats?.legacyFilters.confidence)}
                                </TableCell>
                            </TableRow>
                            }
                            {stats?.legacyFilters.unique_peptides !== null &&
                            <TableRow data-cy={espDataCy.numberOfProteins}>
                                <TableCell component="th" scope="row">
                                    Unique Peptides:
                                </TableCell>
                                <TableCell>Yes</TableCell>
                                {stats?.mviTmpFilters && <TableCell>
                                    {stats?.mviTmpFilters.unique_peptides} rows {stats?.calculatePercentage(stats?.mviTmpFilters.unique_peptides)}
                                </TableCell>}
                                <TableCell>
                                    {stats?.legacyFilters.unique_peptides} rows {stats?.calculatePercentage(stats?.legacyFilters.unique_peptides)}
                                </TableCell>
                            </TableRow>
                            }
                        </TableBody>
                        {stats?.unique_peptides_filter_count !== null &&
                        <TableFooter>
                            <TableRow data-cy={espDataCy.filterSummary}>
                                <TableCell component="th" scope="row" colSpan={2}>
                                    Removed PSM Rows Total:
                                </TableCell>
                                {stats?.mviTmpFilters && <TableCell>
                                    <Box display="flex" flexDirection="row"
                                        alignItems="center" justifyContent="space-between"
                                    >
                                        {stats?.mviTmpFilters.total_number_of_rows_removed} rows {stats?.calculatePercentage(stats?.mviTmpFilters.total_number_of_rows_removed)}
                                    </Box>
                                </TableCell>}
                                <TableCell>
                                    <Box display="flex" flexDirection="row"
                                        alignItems="center" justifyContent="space-between"
                                    >
                                        {stats?.legacyFilters.total_number_of_rows_removed} rows {stats?.calculatePercentage(stats?.legacyFilters.total_number_of_rows_removed)}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                        }
                    </StyledListTable>
                </TableContainer>
            </Box>
            <Typography component="li" data-cy={espDataCy.skipNormalization}>
                <strong>Skip TMT abundance normalization:{' '}</strong>
                {experimentAnalysis?.skip_normalization ? "yes" : "no"}
            </Typography>
        </StyledListBox>
            
        <Box display="flex">
            <div>
                <Typography data-cy={espDataCy.psmLabel}
                    variant="subtitle2" component="p" align="center" gutterBottom
                >
                    PSMs
                </Typography>
                <div data-cy={espDataCy.psmPie}>
                    <Pie
                        height={80}
                        width={120}
                        margin={{left: 20, right: 20}}
                        data={stats ? stats.psmPlotData : []}
                        colors={chartColors}
                        innerRadius={0.5}
                    />
                </div>
            </div>
            <Box mr={2}>
                <Typography data-cy={espDataCy.removedLabel} 
                    variant="subtitle2" component="p" align="center" gutterBottom
                >
                    Removed
                </Typography>
                <div data-cy={espDataCy.removedBar}>
                    <Bar
                        height={stats ? stats.removalPlotData.length * 20 : 80}
                        width={300}
                        margin={ {left: 160}  }
                        layout="horizontal"
                        enableLabel = {false}
                        data={stats ? stats.removalPlotData: []}
                        indexBy='category'
                        keys={['placeholder', 'value']}
                        colors={{scheme: 'category10'}}
                        animate={true}
                    />
                </div>
            </Box>
            <Box mr={1}>
                <Typography data-cy={espDataCy.proteinsLabel} 
                    variant="subtitle2" component="p" align="center" gutterBottom
                >
                    {PTM_TYPES.includes(experimentType.value) ? "PTM sites" : "Proteins"}
                </Typography>
                <div data-cy={espDataCy.proteinsPie}>

                    <Pie
                        height={80}
                        width={120}
                        margin={{left: 20, right: 20}}
                        data={stats ? stats.proteinPlotData : []}
                        colors={chartColors}
                        innerRadius={0.5}
                    />
                </div>
            </Box>
            {stats?.peptidePlotData ?
            <div>
                <Typography data-cy={espDataCy.proteinsLabel} 
                    variant="subtitle2" component="p" align="center" gutterBottom
                >
                    PSMs
                </Typography>
                <Bar
                    height={80}
                    width={240}
                    margin={{top: 0, right:0, bottom:0, left: 80}}
                    layout="horizontal"
                    data={stats.peptidePlotData}
                    indexBy='category'
                    keys={['placeholder', 'value']}
                    colors={chartColors}
                    animate={true}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                />
            </div>
            : null }
        </Box>
    </>
}