import React, {BaseSyntheticEvent, FC, useState} from 'react'
import {
    Box, Button, ButtonGroup, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
    TextField, Theme, useTheme
} from '@mui/material'
import barcodeService from '../../services/barcodeService';
import {visuallyHidden} from '@mui/utils';
import {OptionsObject, useSnackbar} from 'notistack';
import isNil from 'lodash/isNil';

interface PairMapperProps {
    pairs: BarcodePair[];
    setPairs: (updated: BarcodePair[]) => void;
}
const PairMapper: FC<PairMapperProps> = ({pairs, setPairs}) => {
    
    const theme = useTheme<Theme>();
    
    const [parent, setParent] = useState<string>('')
    const [parentError, setParentError] = useState(false)
    const [child, setChild] = useState<string>('')
    const [childError, setChildError] = useState(false)
    const [editableParent, setEditableParent] = useState<string>('')
    const [editableChild, setEditableChild] = useState<string>('')
    const {enqueueSnackbar} = useSnackbar();
    const [editableRow, setEditableRow] = useState<number>(-1)

    const deleteFn = async (parent: string, child: string) => {
        // console.log(`deleting parent: ${parent} and child: ${child}`)
        const deleted = await barcodeService.deleteBarcode(parent, child)
        if (!isNil(deleted?.keys)) {
            const updatedPairs = [...pairs]
            const index = updatedPairs.findIndex((barcode: BarcodePair) => barcode.parent === parent && barcode.child === child)
            updatedPairs.splice(index, 1)
            setPairs(updatedPairs)
        } else {
            enqueueSnackbar("Unexpected error deleted pair",  {variant: 'error', persist: true});
        }

    }
    const handlePairSubmit = async (event: BaseSyntheticEvent) => {
        event.preventDefault()
        const cleanParent = parent.trim()
        const cleanChild = child.trim()
        let doSumbit = true
        if (cleanParent === '') {
            setParentError(true)
            doSumbit = false
        }
        if (cleanChild === '') {
            setChildError(true)
            doSumbit = false
        }
        if (cleanParent === cleanChild) {
            setParentError(true)
            setChildError(true)
            doSumbit = false
        }
        if (doSumbit) {
            const updated = await barcodeService.updateBarcode(cleanParent, cleanChild)
            console.log(`Updated is ${JSON.stringify(updated)}`)
            if (!isNil(updated?.keys)) {
                // console.log('keys are not null')
                const updatedPairs = [...pairs]
                updatedPairs.push({id: parent, parent, child})
                setParent('')
                setChild('')
                setPairs(updatedPairs)
            } else {
                const reasonsString = updated?.cancellationReasons as unknown as string
                const reasons = JSON.parse(reasonsString)
                const reason = reasons.find((el: any) => el.item !== null)
                const errorOptions: OptionsObject = {
                    variant: 'error',
                    persist: true
                };
                if (reason) {
                    const index = pairs.findIndex((pair: BarcodePair) => pair.parent === cleanParent || pair.child === cleanChild)
                    let msg = "Unable to save the pair. Barcodes must be unique and one of the barcodes has "
                        + "already been used. "
                    console.log(`Index is ${index}`, pairs)
                    if (index === -1) {
                        const updatedPairs = [...pairs]
                        updatedPairs.push(reason.item)
                        setPairs(updatedPairs)
                        msg = msg + "The pair with the duplicate has been added to your list. "
                    } else {
                        msg = msg + "The duplicate is in your list. "
                    }
                    msg = msg + "Delete the record with the duplicate and save again if the existing record is incorrect."
                    enqueueSnackbar(msg, errorOptions);
                } else {
                    enqueueSnackbar("Unexpected error saving", errorOptions);
                }
            }
        }
    }

    const handlePairEdit = async (event: BaseSyntheticEvent) => {
        event.preventDefault()
        const original = pairs[editableRow]
        const cleanParent = editableParent.trim()
        const cleanChild = editableChild.trim()
        await barcodeService.deleteBarcode(original.parent, original.child)
        const updated = await barcodeService.updateBarcode(cleanParent, cleanChild)
        //console.log(`handlePairEdit is ${JSON.stringify(updated)}`)
        //console.log(`value of updated.keys is ${updated.keys}`, updated.keys)
        //console.log(`The type of updated.keys is ${typeof updated.keys} and ${typeof updated}`)
        if (!isNil(updated?.keys)) {
            //console.log('keys are not null')
            const updatedPairs = [...pairs]
            updatedPairs[editableRow] = {id: editableParent, parent: editableParent, child: editableChild}
            setEditableParent('')
            setEditableChild('')
            setPairs(updatedPairs)
            setEditableRow(-1)
        } else {
            const errorOptions: OptionsObject = {
                variant: 'error',
                persist: true
            };
            const reasonsString = updated?.cancellationReasons as unknown as string
            const reasons = JSON.parse(reasonsString)
            const reason = reasons.find((el: any) => el.item !== null)
            // put the original back since the "edit" failed
            const updatedOriginal = await barcodeService.updateBarcode(original.parent, original.child)
            console.log('put the original back', updatedOriginal)
            if (reason) {
                const index = pairs.findIndex((pair: BarcodePair, index) => {
                            return index !== editableRow && (pair.parent === cleanParent || pair.child === cleanChild)
                        })
                let msg = "Unable to save the pair. Barcodes must be unique and one of the barcodes has "
                    + "already been used. "
                console.log(`Index is ${index}`, pairs)
                if (index === -1) {
                    const updatedPairs = [...pairs]
                    updatedPairs.push(reason.item)
                    setPairs(updatedPairs)
                    msg = msg + "The pair with the duplicate has been added to your list. "
                } else {
                    msg = msg + "The duplicate is in your list. "
                }
                msg = msg + "Delete the record with the duplicate and save again if the existing record is incorrect."
                enqueueSnackbar(msg, errorOptions);
            } else {
                enqueueSnackbar("Unexpected error saving", errorOptions);
            }
        }
    }
    
    const sxTableContainer = {
        minHeight: 'calc(600px - 130px)',
        overflowY: 'scroll',
        borderRadius: 1.5,
        [theme.breakpoints.down(theme.breakpoints.values.fhd)]: { maxHeight: '50vh'},
        [theme.breakpoints.between(theme.breakpoints.values.fhd, theme.breakpoints.values.qhd)]: { maxHeight: '60vh'},
        [theme.breakpoints.up(theme.breakpoints.values.qhd)]:{ maxHeight: '70vh'}
    };
    const sxTable = {
        '& .MuiTableCell-root': { 
            [theme.breakpoints.up(theme.breakpoints.values.fhd)]: { fontSize: theme.typography.body1.fontSize}
        }
    };
    const sxEditableTableCell = {
        py: 2
    };
    const sxButtonGroup = {
        backgroundColor: theme.palette.grey[50], 
        '& > button': { width: 62}
    };
    
    return (
        <Box name="createpair" component="form" autoComplete="off">
            <Grid container rowSpacing={2} justifyContent="center">
                <Grid container item xs={12} xl={7} spacing={1}>
                    <Grid item xs={4} lg={3}>
                        <TextField
                            required
                            helperText="Unique Value Required"
                            id="barcode"
                            name="barcode"
                            value={parent}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setParent(event.target.value)
                                if (event.target.validity.valid) {
                                    setParentError(false)
                                } else {
                                setParentError(true)
                                }
                            }}
                            label="Cell culture barcode"
                            variant="outlined" size="small" fullWidth
                            error={parentError}
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <TextField
                            required
                            helperText="Unique Value Required"
                            id="peptideBarcode"
                            name="peptideBarcode"
                            value={child}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setChild(event.target.value)
                                if (event.target.validity.valid) {
                                    setChildError(false)
                                } else {
                                    setChildError(true)
                                }
                            }}
                            label="Peptide plate barcode"
                            variant="outlined" size="small" fullWidth
                            error={childError}
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <Button type="submit" size="large" onClick={handlePairSubmit}
                            disabled={parentError || childError}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container rowSpacing={2} justifyContent="center">
                <Grid item xs={12} xl={7}>
                    <Box mt={2} border={2} borderColor={'grey.200'} borderRadius={2} sx={{overflow: 'hidden'}}>
                        <TableContainer sx={sxTableContainer}>
                            <Table size="small" sx={sxTable} stickyHeader>
                                <colgroup>
                                    <col span={1} style={{width: 0}} />
                                    <col span={1} style={{width: 240}} />
                                    <col span={1} style={{width: 240}} />
                                    <col span={1} style={{width: 'auto'}} />
                                </colgroup>
                                {pairs.length === 0 && <caption>Enter barcode pairs to begin.</caption>}
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={visuallyHidden}>ID</TableCell>
                                        <TableCell>Cell culture barcode</TableCell>
                                        <TableCell>Peptide barcode</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pairs.map((row: BarcodePair, index) => {
                                        if (index === editableRow) {
                                        return (
                                        <TableRow key={row.id}>
                                            <TableCell sx={visuallyHidden}>{row.id}</TableCell>
                                            <TableCell sx={sxEditableTableCell}>
                                                <TextField
                                                    required
                                                    helperText="Required"
                                                    id="barcode"
                                                    name="barcode"
                                                    value={editableParent}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setEditableParent(event.target.value)}}
                                                    label="Parent plate barcode"
                                                    variant="standard" size="small" fullWidth
                                                />
                                            </TableCell>
                                            <TableCell sx={sxEditableTableCell}>
                                                <TextField
                                                    required
                                                    helperText="Required"
                                                    id="peptideBarcode"
                                                    name="peptideBarcode"
                                                    value={editableChild}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setEditableChild(event.target.value)}}
                                                    label="Peptide plate barcode"
                                                    variant="standard" size="small" fullWidth
                                                />
                                            </TableCell>
                                            <TableCell sx={sxEditableTableCell}>
                                                <ButtonGroup variant="text" sx={sxButtonGroup}>
                                                    <Button onClick={handlePairEdit}>save</Button>
                                                    <Button onClick={() => {
                                                        setEditableRow(-1)
                                                        setEditableParent('')
                                                        setEditableChild('')
                                                    }}>
                                                        cancel
                                                    </Button>
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                        )}
                                        return (
                                        <TableRow key={row.id}>
                                            <TableCell sx={visuallyHidden}>{row.id}</TableCell>
                                            <TableCell>{row.parent}</TableCell>
                                            <TableCell>{row.child}</TableCell>
                                            <TableCell>
                                                <ButtonGroup variant="text" sx={sxButtonGroup}>
                                                    <Button
                                                        onClick={() => {
                                                        setEditableRow(index)
                                                        setEditableParent(row.parent)
                                                        setEditableChild(row.child)
                                                    }}>
                                                        edit
                                                    </Button>
                                                    <Button onClick={() => deleteFn(row.parent, row.child)}>delete</Button>
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default PairMapper