import {useEffect, useState} from "react";
import InputLabel from "@mui/material/InputLabel";
import LinearProgress from "@mui/material/LinearProgress";
import {Alert} from "@mui/material";
import { get } from "lodash";
import experimentsService from '../../../../../../../services/experimentsService';
import apiService from '../../../../../../../services/apiService';
import {PDVersion, QuantitationMethod, TMT} from '../../../../../../../API';

interface UploadProgressProps {
    experimentName: string;
    experimentType: string;
    psmFile: any;
    experimentFolder: string;
    database: string;
    benchling: string
    instrument: string;
    psmThreshold: number;
    isolationInterferenceCutoff: number;
    averageReporterSNCutoff: number;
    missingChannelThreshold: number;
    spsMassMatches: number | null | undefined;
    ignoreSpsMassMatches: boolean;
    skipNormalization: boolean;
    psmAmbiguityOptions: string[];
    identifyingNodeOptions: string[];
    tmt: TMT;
    tmtLot: string;
    pdVersion: PDVersion;
    injectionNumber: number | null | undefined;
    handleUploadFinished: () => void;
}

const UploadProgress = ({experimentName, experimentType, psmFile, database, benchling, instrument, psmThreshold,
                            isolationInterferenceCutoff, averageReporterSNCutoff, missingChannelThreshold,
                            spsMassMatches, ignoreSpsMassMatches, skipNormalization, psmAmbiguityOptions,
                            identifyingNodeOptions, tmt, tmtLot, pdVersion, experimentFolder,
                            injectionNumber, handleUploadFinished}: UploadProgressProps) => {

    const [psmFileProgress, setPsmFileProgress] = useState(0);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [progressColor, setProgressColor] = useState<'primary'|'secondary'>('primary');

    const psmFileName = 'psms/' + experimentName + '_PSMs.txt'

    useEffect(() => {
        let subscription: any;
        const uploadFile = async () => {
            try {
                const completedAnalysisStep = experimentsService.observeAnalysis()
                subscription = completedAnalysisStep.subscribe({
                    next: function(result: any) {
                        console.log('UploadProgress subscribe next called', result)
                        if (result.data.completedAnalysisStep.experimentName === experimentName) {
                            if (result.data.completedAnalysisStep.status === "OK") {
                                handleUploadFinished();
                            } else {
                                setErrorMessage(result.data.completedAnalysisStep.message);
                                setProgressColor('secondary');
                                setPsmFileProgress(100);
                            }
                        }
                    },
                    error(err: any) {
                        console.log('Error: ' + err);
                        handleUploadFinished();
                    },
                    complete() {
                        console.log('COMPLETE');
                        handleUploadFinished();
                    }
                });
                const signedUrl = await apiService.fetchUploadUrl(psmFileName)
                const success = await apiService.putWithProgress(signedUrl.url, psmFile, setPsmFileProgress)
                setPsmFileProgress(100)
                if (success && !!tmt) {
                    const input = {
                        experimentName: experimentName,
                        experimentType: experimentType,
                        psmFile: psmFile.name,
                        experimentFolder: experimentFolder,
                        database: database,
                        instrument: instrument,
                        benchling: benchling,
                        psmThreshold: psmThreshold,
                        isolationInterferenceCutoff: isolationInterferenceCutoff,
                        averageReporterSNCutoff: averageReporterSNCutoff,
                        missingChannelThreshold: missingChannelThreshold,
                        spsMassMatchesCutoff: spsMassMatches,
                        ignoreSpsMassMatches: ignoreSpsMassMatches,
                        skipNormalization: skipNormalization,
                        psmAmbiguityOptions: psmAmbiguityOptions,
                        identifyingNodeOptions: identifyingNodeOptions,
                        tmt: tmt,
                        tmtLot: tmtLot,
                        pdVersion: pdVersion,
                        injectionNumber: injectionNumber,
                        quantitationMethod: QuantitationMethod.MS_3
                    }
                    await experimentsService.doAnalysis(input)
                } else if (success && !tmt) {
                    const input = {
                        experimentName: experimentName,
                        experimentType: experimentType,
                        psmFile: psmFile.name,
                        experimentFolder: experimentFolder,
                        database: database,
                        instrument: instrument,
                        benchling: benchling,
                        pdVersion: pdVersion,
                    }
                    // console.log('fixing to call startDiaAnalysis with', input)
                    const results  = await experimentsService.doDiaAnalysis(input)
                    if (results.status === 'FAILED') {
                        setErrorMessage(results.message as unknown as string)
                    }
                } else {
                    setErrorMessage('PSM file upload error')
                }
            } catch(error: any) {
                console.log("UPLOAD ERROR", error);
                const errorMessage = get(error, 'errors[0].message', 'unknown error');
                setErrorMessage(`Upload failure: ${error?.message ?? errorMessage}`);
            }
        };
        void uploadFile()
        return function cleanup() {
            if (subscription) {
                subscription.unsubscribe();
            }
        }
        // Using empty array to ensure this hook only runs once.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
        <InputLabel htmlFor="psm-file-progress">PSM file upload progress</InputLabel>
        {psmFileProgress === 0 ?
            <LinearProgress color={progressColor}/>
            :
            <LinearProgress variant="determinate" value={psmFileProgress} color={progressColor}/>
        }
        <InputLabel htmlFor="psm-conversion-progress">PSM conversion progress</InputLabel>
        {errorMessage ?
            <Alert severity="error">{errorMessage}</Alert>
            :
            <LinearProgress/>
        }
    </>
}

export default UploadProgress;