import {generateClient} from 'aws-amplify/api'
import {updateLcmsInfo} from '../graphql/mutations'
import {LcmsFilters, LcmsStatus} from '../API';
import {getLcmsStatus} from '../graphql/queries';
import {handleError} from '../util/util';

const lcmsStatus = {
    fetchData: async (filters: LcmsFilters): Promise<Array<LcmsStatus | null > | null> => {
        try {
            const result = await generateClient().graphql({query: getLcmsStatus, variables: {filters: filters}})
            return result.data.getLcmsStatus.items as unknown as Promise<Array<LcmsStatus | null > | null>
        } catch (error) {
            console.error('fetchData Error', error)
            handleError(error)
            return null
        }
    },
    updateLcms: async (experiment: string, start: string, end: string, instrument: string) => {
        try {
            const result = await generateClient().graphql({query: updateLcmsInfo,
                variables: {experiment, start, end, instrument}})
            return result.data.updateLcmsInfo
        } catch (error) {
            console.error('updateLcms Error', error)
            handleError(error)
            return null
        }
    }
}

export default lcmsStatus