/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const completedPublishStep = /* GraphQL */ `subscription CompletedPublishStep {
  completedPublishStep {
    status
    experimentName
    message
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.CompletedPublishStepSubscriptionVariables,
  APITypes.CompletedPublishStepSubscription
>;
export const completedAnalysisStep = /* GraphQL */ `subscription CompletedAnalysisStep {
  completedAnalysisStep {
    status
    experimentName
    message
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.CompletedAnalysisStepSubscriptionVariables,
  APITypes.CompletedAnalysisStepSubscription
>;
export const pcaAnalysisForLabelCheck = /* GraphQL */ `subscription PcaAnalysisForLabelCheck {
  pcaAnalysisForLabelCheck {
    experimentName
    plot {
      id
      data {
        x
        y
        __typename
      }
      __typename
    }
    message
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.PcaAnalysisForLabelCheckSubscriptionVariables,
  APITypes.PcaAnalysisForLabelCheckSubscription
>;
export const completedDownloadConversion = /* GraphQL */ `subscription CompletedDownloadConversion {
  completedDownloadConversion {
    experiment
    fileType
    method
    url
    key
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.CompletedDownloadConversionSubscriptionVariables,
  APITypes.CompletedDownloadConversionSubscription
>;
export const completeFlashSampleList = /* GraphQL */ `subscription CompleteFlashSampleList {
  completeFlashSampleList {
    status
    experimentName
    message
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.CompleteFlashSampleListSubscriptionVariables,
  APITypes.CompleteFlashSampleListSubscription
>;
