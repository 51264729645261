import React, {FC, FormEvent, useCallback, useState} from 'react'
import flashService from '../../services/flashService';
import {Box, CircularProgress, IconButton, TextField} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import BarcodeInfoTable from './BarcodeInfoTable';

const BarcodeInfo: FC = () => {

    const [barcode, setBarcode] = useState<string>('')
    const [info, setInfo] = useState<any[]|null>(null)
    const [loading, setLoading] = useState<boolean>(false)

    const doSearch = useCallback(async () => {
        // console.log(`doing the search for ${barcode}`)
        setLoading(true)
        const data = await flashService.getBarcodeInfo(barcode)
        // console.log(`Our data is ${typeof data}`, data)
        setInfo(data)
        setLoading(false)
    }, [barcode])

    return (
        <Box component="section">
            <Box component="form" autoComplete="off"
                sx={{'& .MuiTextField-root': { m:1, width: '25ch'}}}
                 onSubmit={(event:FormEvent<HTMLFormElement>) => {
                     event.preventDefault()
                     doSearch()
                 }}
            >
                <TextField required helperText="Search Peptide plate barcode info"
                          value={barcode}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setBarcode(event.target.value)
                          }}
                          disabled={loading}
                          label="Peptide plate barcode"
                          variant="outlined" size="small" fullWidth />
                <IconButton onClick={doSearch} disabled={loading}>
                    <SearchIcon />
                </IconButton>
            </Box>
            {loading ? <Box display="flex" justifyContent="center">
                <CircularProgress />
            </Box> :
                <BarcodeInfoTable data={info} />
            }
        </Box>
    )
}

export default BarcodeInfo