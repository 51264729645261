import {generateClient} from 'aws-amplify/api';
import {
    dispatchPostgresFunction,
    getFastaUploadUrl,
    getLabelEfficiencyUploadUrl,
    getResource,
    getUploadUrl
} from '../graphql/queries';
import {SignedUrl} from '../API';
import {validateFastaUpload} from '../graphql/mutations';
import {handleError} from '../util/util';

const api = {
    fetchCellLines: async (): Promise<CellLine[]> => {
        try {
            const result = await generateClient().graphql({query: dispatchPostgresFunction,
                variables: {input: {function_name: "get_cell_line", function_parameters: JSON.stringify({})}}})
            if (result.data.dispatchPostgresFunction) {
                return JSON.parse(result.data.dispatchPostgresFunction)
            }
            return []
        } catch (err) {
            console.error('fetchCellLines Error', err)
            handleError(err)
            return []
        }
    },
    /** Gets the compounds from postgres and HARD CODED additions of proteasome inhibitors and DMSO */
    fetchCompounds: async (): Promise<RefCompound[]> => {
        try {
            const result = await generateClient().graphql({query: dispatchPostgresFunction, variables:
                {
                    input: {
                        function_name: "fetch_ordered_compounds_with_batches",
                        function_parameters: JSON.stringify({})
                    }
                }})
            if (result.data.dispatchPostgresFunction) {
                const rawCompounds = JSON.parse(result.data.dispatchPostgresFunction)
                const refCompounds = rawCompounds.map((element: any) => {
                    return {
                        value: element.molecule_name,
                        batches: element.batches_array.map((num: number) => String(num))
                    }
                }) as RefCompound[]

                refCompounds.unshift({value: 'Bortezomib', batches: ['1']})
                const index = refCompounds.findIndex((element: RefCompound) => element.value.startsWith('MRT'))
                refCompounds.splice(index, 0, {value: 'DMSO', batches: ['1']}, {value: 'MLN4924', batches: ['1']})
                return refCompounds
            }
            return []
        } catch (err) {
            console.error('fetchCompounds Error', err)
            handleError(err)
            return []
        }
    },
    fetchPossibleUsers: async (): Promise<CddValue[]> => {
        try {
            const response = await generateClient().graphql({query: dispatchPostgresFunction, variables:
                {
                    input: {
                        function_name: "get_active_users",
                        function_parameters: JSON.stringify({})
                    }
                }});
            if (response.data.dispatchPostgresFunction) {
                const data = JSON.parse(response.data.dispatchPostgresFunction)
                return data.map((item: any) => {
                    return {'value': item.first_name + " " + item.last_name};
                });
            }
            return []
        } catch (error) {
            console.error('fetchPossibleUsers Error', error);
            handleError(error)
        }
        return []
    },
    fetchResource: async (key: string): Promise<any> => {
        try {
            const response = await generateClient().graphql({query: getResource, variables: {resource: key}})
            // console.log(`OUR REPONSE is ${JSON.stringify(response)}`)
            return response.data.getResource
        } catch (error) {
            console.error('fetchResource Error', error)
            handleError(error)
        }
        return ''
    },
    fetchFastaUploadUrl: async (fileName: string): Promise<SignedUrl> => {
        try {
            const signed = await generateClient().graphql({query: getFastaUploadUrl,
                variables: {key: fileName}})
            return signed.data.getFastaUploadUrl
        } catch (error) {
            console.error('fetchFastaUploadUrl Error', error)
            handleError(error)
            return {__typename: 'SignedUrl', url: '', key: ''}
        }
    },
    fetchUploadUrl: async (fileName: string): Promise<SignedUrl> => {
        try {
            const signed = await generateClient().graphql({query: getUploadUrl,
                variables: {key: fileName}})
            return signed.data.getUploadUrl
        } catch (error) {
            console.error('fetchUploadUrl Error', error)
            handleError(error)
            return {__typename: 'SignedUrl', url: '', key: ''}
        }
    },
    fetchLabelEfficiencyUploadUrl: async (fileName: string): Promise<SignedUrl> => {
        try {
            const signed = await generateClient().graphql({
                query: getLabelEfficiencyUploadUrl,
                variables: {key: fileName}
            })
            return signed.data.getLabelEfficiencyUploadUrl
        } catch (error) {
            console.error('fetchLabelEfficiencyUploadUrl Error', error)
            handleError(error)
            return {__typename: 'SignedUrl', url: '', key: ''}
        }
    },
    validateFastaUpload: async (fileName: string) => {
        try {
            const response = await generateClient().graphql({query: validateFastaUpload,
                variables: {input: {key: fileName}}})
            console.log("RESPONSE", response)
            return response.data.validateFastaUpload
        } catch (error) {
            console.error('fetchLabelEfficiencyUploadUrl Error', error)
            handleError(error)
            return 'ERROR'
        }
    },
    putWithProgress: async (url: string, file: File, progressCallback: (p: number) => void): Promise<boolean> => {
        const xhr = new XMLHttpRequest()
        const success = await new Promise((resolve: (result: boolean) => void) => {
            xhr.upload.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    progressCallback(event.loaded /event.total * 100)
                }
            })
            xhr.addEventListener('loadend', () => {
                resolve(xhr.readyState === 4 && xhr.status === 200)
            })
            xhr.open("PUT", url, true)
            xhr.setRequestHeader('Content-Type', 'binary/octet-stream')
            xhr.send(file)
        })
        return success
    }
}

export default api