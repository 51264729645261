import React, { useState } from 'react';
import { Alert, Dialog } from '@mui/material/';
import Snackbar from '@mui/material/Snackbar';
import {Hub} from 'aws-amplify/utils';
import {CreateNewExperimentModal,
    CreateNotTmtExperimentModal} from '../../modals/CreateNewExperiment';
import DesignSelectForm from "./DesignSelectForm";
import designs from '../../../services/designs';
import {experimentTypes} from '../../../util/options';
import {Continuable, Selection} from './DesignSelectForm/DesignSelectForm';

type NewExpModalProps = {
    open: boolean;
    handleClose: () => void;
}

export default function NewExperimentModal({open, handleClose}: NewExpModalProps) {
    const [showContinueAsNew, setShowContinueAsNew] = useState(false);
    const [showNotTmt, setShowNotTmt] = useState(false);
    const [experimentDesign, setExperimentDesign] = useState<any>();
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('')

    const handleNewExperimentClose = () => {
        setExperimentDesign(null);
        setShowContinueAsNew(false);
        setShowNotTmt(false)
    }
    const handleSuccess = (message: string, designType: ExperimentType) => {
        // console.log(`Saved design of type: ${designType.value}`)
        setSuccessMessage(message);
        setOpenSuccess(true);
        Hub.dispatch('DesignChannel', {event: 'new', data: {type: designType}})
    }

    const doNewFlash = async (value: string) => {
        const type = experimentTypes.find((type) => type.value === value)!
        const experiment: string = await designs.createName(type)
        let design: any = {
            experiment,
            record_type: "DESIGN",
            record_value: {
                type: type.value,
                projectName: '',
                responsible: '',
                collaborator: '',
                benchling: '',
                peptideBarcode: '',
                noOfSamples: 12}
        }
        let status: any = {
            experiment,
            record_type: type.list
        }
        const experimentList = [design, status]
        const results: any = await designs.uploadPlanned(experimentList)
        if (results) {
            const msg = `Experiment with name '${results.data.uploadPlannedExperiment[0]?.experiment}' has been successfully created!`
            handleSuccess(msg, type)
        }
    }

    const handleContinue = (continueAs: Continuable) => {
        if (continueAs === 'TMT') {
            setShowContinueAsNew(true)
        } else if (continueAs === Selection.DIA) {
            setShowNotTmt(true)
        } else if (continueAs === Selection.FLASH_DEGRADATION || continueAs === Selection.FLASH_TURBO_ID) {
            doNewFlash(continueAs)
        }
        handleClose()
    }

    const handleSuccessClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessMessage('');
        setOpenSuccess(false);
    };

    const action = (
        <Alert elevation={6} variant="filled" severity="success" onClose={handleSuccessClose}>
            {successMessage}
        </Alert>
    )

    return (<>
        <Dialog
            fullWidth
             maxWidth={'sm'}
             PaperProps={{
                square: true,
                elevation: 0,
                sx: {border: '2px solid black'}
             }}
            open={open}
            onClose={() => handleClose}
            aria-labelledby={'select-new-exp-type-title'}
        >
            <DesignSelectForm setExperimentDesign={setExperimentDesign} handleClose={handleClose}
                handleContinue={handleContinue} />
        </Dialog>
        {showContinueAsNew && <CreateNewExperimentModal
            handleClose={handleNewExperimentClose}
            experimentDesign={experimentDesign} handleSuccess={handleSuccess} />}
        {showNotTmt && <CreateNotTmtExperimentModal handleClose={handleNewExperimentClose}
                                                    handleSuccess={handleSuccess}
                                                    experimentDesign={experimentDesign} />}
        <Snackbar
            open={openSuccess}
            autoHideDuration={6000}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            onClose={handleSuccessClose}
            action={action}
        />
    </>);
}