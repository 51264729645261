import {useContext, useEffect, useState} from 'react';
import { Box, Grid, Typography } from '@mui/material';
import ContrastSelectModal from '../../modals/ContrastSelectModal';
import useMounted from '../../../util/useMounted';
import VolcanoPlot from './VolcanoPlot';
import {ReferenceContext} from '../../../store/ReferenceContext';
import details from '../../../services/details';
import {ExperimentTypeContext} from '../../../store/ExperimentTypeContext';

export interface WorkingContrast {
    selected: boolean;
    contrast: string;
    display: string;
    index: number;
    control: boolean
}

interface VolcanoPanelProps {
    experimentName: string;
}

function createHumanReadingNamesForBenchlingIds(contrast: string, cellLines: CellLine[]) {
    const matches = contrast.match(/(Cell[\d]{5})/g)
    //console.log(`CONTRAST: ${contrast}`, matches)
    if (matches && matches.length === 2) {
        const firstLine = cellLines.find((cellLine: CellLine) => cellLine.benchling_id === matches[0])
        const secondLine = cellLines.find((cellLine: CellLine) => cellLine.benchling_id === matches[1])
        if (firstLine && secondLine) {
            let display = contrast.replace(matches[0], firstLine.cell_line_short_name)
            //console.log(`DISPLAY: ${display} and matches[1]: ${matches[1]} and ${secondLine.cell_line_short_name}`)
            return display.replace(matches[1], secondLine.cell_line_short_name)
        } else {
            return contrast
        }
    }
    return contrast
}

export default function VolcanoPanel({experimentName}: VolcanoPanelProps) {
    
    const isMounted = useMounted();
    const [contrasts, setContrasts] = useState<WorkingContrast[]>([]);
    const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
    const [selectedContrasts, setSelectedContrasts] = useState<string[]>([])
    const referenceContext = useContext<References>(ReferenceContext)
    const experimentType = useContext<ExperimentType>(ExperimentTypeContext)
    const tkoExperiment = experimentType.value === 'QC'

    useEffect(() => {
        const fetchContrasts = async (experimentName: string) => {
            setSelectedContrasts([]);
            setSelectedIndexes([]);
            const ctl = /DMSO/;
            const oldTkoCtl = /PARENTAL/;
            const data: string[] = await details.fetchContrasts(experimentName);
            const workingContrasts: WorkingContrast[] = data.map((s: string, index:number) => {
                const name: string = tkoExperiment
                    ? createHumanReadingNamesForBenchlingIds(s, referenceContext.cellLines) : s
                const isControl = tkoExperiment
                    ? s.endsWith('Cell02885') || s.endsWith('wt') || oldTkoCtl.test(s) : ctl.test(s)
                return {
                    selected: false,
                    contrast: s,
                    display: name,
                    index: index,
                    control: isControl
                }
            })
            setContrasts(workingContrasts);
        }
        fetchContrasts(experimentName);
    }, [experimentName, isMounted, tkoExperiment, referenceContext.cellLines]);

    const handlePlotting = (list: number[]) => {
        const next: string[] = list.map((i: number) => contrasts[i].contrast);
        setSelectedIndexes(list);
        setSelectedContrasts(next);
    }
    
    return (<>
        <Typography paragraph>
            {selectedIndexes.length} of {contrasts.length} possible contrasts selected.
        </Typography>
        <Box role="toolbar" mb={2}>
            <ContrastSelectModal contrasts={contrasts}
                                 selectionHandler={handlePlotting}
                                 handleBenchlingIds={tkoExperiment}/>
        </Box>
        <Grid container spacing={2}>
            {selectedContrasts.map((contrast) => {
                const key = contrast.replaceAll(' ', '');
                return (
                    <VolcanoPlot key={key} contrast={contrast}
                                 experiment={experimentName}
                                 twoColumn={selectedIndexes.length > 1}
                    />
                );
            })}
        </Grid> 
    </>);
}