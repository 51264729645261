import {ChangeEvent, FC, useEffect, useState} from "react";
import {
    Autocomplete, Box, Button,
    Fade, FormControl, FormLabel,
    DialogActions, DialogContent, DialogTitle,
    TextField, Typography,
} from "@mui/material";
import {DesignSelectFormDataCy} from "./DesignSelectForm.cy";
import {ExperimentTemplates} from './ExperimentTemplates';
import designs from '../../../../services/designs';
import details from '../../../../services/details';

export enum Selection {
    FromExperiment = 'FromExperiment',
    FromTemplate = 'FromTemplate',
    TMT = 'TMT',
    DIA = 'DIA',
    FLASH_DEGRADATION = 'FLASH_DEGRADATION',
    FLASH_TURBO_ID = 'FLASH_TURBO_ID'
}

const valueOptions = [
    {value: Selection.TMT, label: 'TMT Experiment Design'},
    {value: Selection.DIA, label: 'DIA Experiment'},
    {value: Selection.FLASH_DEGRADATION, label: 'Flash Degradation Experiment'},
    {value: Selection.FLASH_TURBO_ID, label: 'Flash TurboID Experiment'},
]
const fromOptions = [
        {value: Selection.FromExperiment, label: 'Clone Existing'},
        {value: Selection.FromTemplate, label: 'From Template'},
    ]
export type Continuable = 'TMT'|'DIA'|'FLASH_DEGRADATION'|'FLASH_TURBO_ID';

export interface DesignSelectFormProps {
    setExperimentDesign: (design: any) => void;
    handleContinue:(continueAs: Continuable) => void;
    handleClose: () => void;
}

const DesignSelectForm: FC<DesignSelectFormProps> = ({setExperimentDesign, handleContinue, handleClose}) => {
    const [value, setValue] = useState<any>(null);
    const [inputValue, setInputValue] = useState<string>('')
    const [fromValue, setFromValue] = useState<any>(null)
    const [fromInput, setFromInput] = useState<string>('')
    const [experiments, setExperiments] = useState<any[]>([]);
    const [selectedExperiment, setSelectedExperiment] = useState<any>();

    useEffect(() => {
        const fetchExperiments = async () => {
            const experiments = await designs.fetchNonQcExperiments();
            setExperiments(experiments);

        };
        fetchExperiments();
    }, [])

    function closeDialog() {
        handleClose();
        setValue('');
        setSelectedExperiment(null);
    }

    async function submitContinueAsNew() {
        // console.log(`${JSON.stringify(value)} vs ${JSON.stringify(fromValue)}`)
        if (value) {
            handleContinue(value.value)
        } else if (fromValue) {
            let continueAs: Continuable = 'TMT'
            if( fromValue.value === Selection.FromExperiment ) {
                // console.log(`FROM ${JSON.stringify(selectedExperiment)}`)
                const experimentDesign: any = await details.fetchDesign(selectedExperiment.experiment)
                setExperimentDesign(experimentDesign);
                if (!(experimentDesign.conditions?.length > 0)) {
                    continueAs = 'DIA'
                }
            } else if (fromValue.value === Selection.FromTemplate) {
                setExperimentDesign(selectedExperiment.template);
            }
            handleContinue(continueAs);
        }
    }
    const disableContinue = () => {
        if (value) {
            return false
        }
        return !selectedExperiment
    }
    const doCreateNewChange = (e: ChangeEvent<any>, newValue: any) => {
        // @ts-ignore
        setValue(newValue)
    }

    return <>
        <DialogTitle id={DesignSelectFormDataCy.header} data-cy={DesignSelectFormDataCy.header}>
            New Experiment Design
        </DialogTitle>
        <Box component="form" display="flex" flexDirection="column" sx={{ minHeight: 230 }}
            name="pickcreatetype" action="/" method="POST" noValidate autoComplete="off"
        >
            <DialogContent dividers>
                <FormControl component="fieldset" sx={{ mb: 2, display: 'flex' }} >
                    <FormLabel component="legend" sx={{ mb: 1 }}>
                        Select an option to continue:
                    </FormLabel>
                    <Autocomplete
                        id="createNewSelection"
                        value={value}
                        getOptionLabel={(option) => option.label ? option.label : ''}
                        isOptionEqualToValue={(option, value) => {
                            // @ts-ignore
                            return option.value === value?.value || value.value === ''
                        }}
                        onChange={doCreateNewChange}
                        inputValue={inputValue}
                        onInputChange={(e: ChangeEvent<any>, newValue: string) => setInputValue(newValue)}
                        options={valueOptions}
                        renderInput={(params) =>
                            <TextField {...params} label="Create New"
                                                   placeholder="Select or enter option"
                                                   variant="outlined" size="small" fullWidth
                                                   name="createNewType"/>
                            }
                        disabled={!!fromValue}
                        forcePopupIcon openOnFocus selectOnFocus handleHomeEndKeys />
                    <Typography variant="overline" gutterBottom sx={{display: "block"}}>OR</Typography>
                    <Box display="flex" flexDirection="row">
                        <Autocomplete id="createFromSelection" fullWidth sx={{mr: '5px'}}
                              value={fromValue}
                              getOptionLabel={(option) => option?.label ? option.label : ''}
                              isOptionEqualToValue={(option, value) => {
                                  console.log(`from value: ${JSON.stringify(value)}`)
                                  // @ts-ignore
                                  return option?.value === value.value || value.value === ''
                              }}
                              onChange={(e: ChangeEvent<any>, newValue: any) => setFromValue(newValue)}
                              inputValue={fromInput}
                              onInputChange={(e: ChangeEvent<any>, newValue: any) => setFromInput(newValue)}
                              options={fromOptions}
                              renderInput={(params) =>
                                  <TextField {...params} label="Create From"
                                             placeholder="Select or enter option"
                                             variant="outlined" size="small" fullWidth
                                             helperText="Select option"/>
                              }
                              disabled={!!value}
                        />
                        {(!fromValue || fromValue.value === Selection.FromExperiment) &&
                            <Autocomplete fullWidth sx={{ml: '5px'}}
                            id="select-from-exp"
                            data-cy={DesignSelectFormDataCy.experimentList}
                            options={experiments}
                            isOptionEqualToValue={(option, value) => {
                                console.log(`from value: ${JSON.stringify(value)}`)
                                return option.value === value.value || value.value === ''
                            }}
                            getOptionLabel={(option) => option?.experiment ? option.experiment : ''}
                            renderInput={(params) =>
                                <TextField {...params} label="Experiment Name"
                                           placeholder="Select or enter option"
                                           variant="outlined" size="small" fullWidth
                                           helperText="Select experiment to create from"/>
                            }
                            onChange={(e: any, newValue: string | null) => {setSelectedExperiment(newValue);}}
                            disabled={fromValue?.value !== Selection.FromExperiment}
                        />}
                        {fromValue?.value === Selection.FromTemplate &&
                            <Fade in={true}>
                                <Autocomplete fullWidth sx={{ml: '5px'}}
                                    id="select-from-template"
                                    data-cy={DesignSelectFormDataCy.templateList}
                                    options={ExperimentTemplates}
                                    getOptionLabel={(option) => option?.name ? option.name : ''}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            label="Template Name" variant="outlined"
                                                   size="small" fullWidth
                                            helperText="Select template to create from"/>
                                    }
                                    onChange={(e: any, newValue: any | null) => {setSelectedExperiment(newValue);}}
                                />
                            </Fade>}
                    </Box>
                </FormControl>

            </DialogContent>
            <DialogActions sx={{ marginTop: 'auto' }}>
                <Button disabled={disableContinue()} onClick={() => submitContinueAsNew()}>
                    Continue
                </Button>
                <Button data-cy={DesignSelectFormDataCy.cancel} onClick={() => closeDialog()}>
                    Cancel
                </Button>
            </DialogActions>
        </Box>
    </>
}

export default DesignSelectForm;