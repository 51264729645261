import {useContext, useLayoutEffect, useState} from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { StyledCenteringBox } from '../Dashboard/ExperimentsPanel/StyledComponents/';
import {ResponsiveBar} from '@nivo/bar';
import debounce from 'lodash/debounce';
import {StatsData} from 'experiment';
import {ExperimentTypeContext} from '../../store/ExperimentTypeContext';

export const FractionCountsDataCy = {
    histogram: 'fc-histogram'
}

export default function FractionCountsPanel({data, loading}: StatsData) {

    const [graphHeight, setGraphHeight] = useState(window.innerWidth / 3);
    const experimentType = useContext<ExperimentType>(ExperimentTypeContext)

    useLayoutEffect(() => {
        const handleResize = debounce(() => {
            setGraphHeight(window.innerWidth / 3)
        }, 250);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const bottom: any = experimentType.value !== 'QC' ? {
        tickSize: 5,
        tickPadding: 5,
        legend: 'Fraction',
        legendPosition: 'middle',
        legendOffset: 32
    } : null

    if(data  && data.length > 0) {
        return <>
            <Box data-cy={FractionCountsDataCy.histogram} height={graphHeight}>
                <ResponsiveBar
                    data={data}
                    keys={['qpsm', 'psm']}
                    indexBy="fraction"
                    margin={{top: 10, bottom:50, left: 55, right: 10}}
                    padding={0.3}
                    colors={{scheme: 'category10'}}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={bottom}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        legend: 'Count',
                        legendPosition: 'middle',
                        legendOffset: -45
                    }}
                    labelSkipWidth={25}
                    labelSkipHeight={12}
                    animate={true}
                    />
            </Box>
        </>
    }
    if (loading) {
        return <StyledCenteringBox><CircularProgress size={70}/></StyledCenteringBox>
    }
    return <StyledCenteringBox><Typography align="center" paragraph>No plot data</Typography></StyledCenteringBox>

}