import React, {FC, memo, SyntheticEvent, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {
    ChevronLeft as ExpandIcon,
    ChevronRight as MinimizeIcon,
} from '@mui/icons-material';
import {
    Alert, Box, Card, CardHeader, Grid, LinearProgress, Snackbar, Tooltip, useTheme, useMediaQuery
} from '@mui/material';
import { StyledColToggleButton } from '../StyledComponents';
import {useParams} from 'react-router-dom';
import compareByDate from '../../../../CompareExperiments'
import {ExperimentFilters} from "filters";
import ExperimentMenu from './ExperimentMenu';
import {CreateNewExperimentModal, CreateNotTmtExperimentModal, CreateNewFlashExperimentModal}
    from '../../../modals/CreateNewExperiment';
import {codeFromName, emptyType, experimentTypes} from '../../../../util/options';
import {
    DataGrid,
    getGridStringOperators,
    GridColDef,
    GridRowParams,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridValueFormatterParams,
} from '@mui/x-data-grid';
import {ExperimentDetailsPanelDataCy} from './ExperimentDetailsPanel.cy';
import {ExperimentAnalysis, FlashAnalysis, PXPAnalysisMethod, PXPfileType} from '../../../../API';
import {OptionsObject, useSnackbar} from "notistack";
import ExperimentTabs from './ExperimentDetails';
import dateFormat from 'dateformat';
import {Hub} from 'aws-amplify/utils';
import { DisplayContext } from 'src/store/DisplayContext';
import designs from '../../../../services/designs';
import details from '../../../../services/details';
import {ReferenceContext} from '../../../../store/ReferenceContext';
import {useFilters} from '../ExperimentsPanel';
import experimentsService from '../../../../services/experimentsService'
import FlashTabs from './ExperimentDetails/FlashTabs';
import {ExperimentTypeContext} from '../../../../store/ExperimentTypeContext';
import isEmpty from 'lodash/isEmpty'

const usefulFilters = getGridStringOperators().filter(({ value }) =>
    ['equals', 'contains'].includes(value),
);

const COLUMNS: GridColDef[] = [
    {field: 'experiment', headerName: 'Experiment', flex: 1, minWidth: 350, filterOperators: usefulFilters},
    {field: 'date', headerName: 'Date', flex: .33, minWidth: 100, disableColumnMenu: true, type: 'date',
        valueFormatter: (params: GridValueFormatterParams) => `${dateFormat(params.value as string, "dd/mm/yy")}`},
    {field: 'status', headerName: 'Status', flex: .33, minWidth: 110, filterOperators: usefulFilters}
];

export function convertConcentration(unit: string, value: number, toUnit: string) {
    const units: any = {
        mM: 10e-3,
        uM: 10e-6,
        nM: 10e-9,
        pM: 10e-12,
        fM: 10e-15
    }
    if (!units[unit]) {
        throw new Error(`unit ${unit} not supported`);
    }
    if (!units[toUnit]) {
        throw new Error(`toUnit ${unit} not supported`);
    }
    return ((value * units[unit]) / units[toUnit]).toExponential(4);
}

export function concentrationConverter(toUnit: string) {
    return (unit: string, value: number) => convertConcentration(unit, value, toUnit);
}

const ExperimentDetailsPanel: FC = () => {

    const { filters } = useFilters();
    const exportPdfHandler = useRef(() => {});
    const {enqueueSnackbar} = useSnackbar();
    const [experimentType, setExperimentType] = useState<ExperimentType>(emptyType);
    const [experiments, setExperiments] = useState<Map<string, any>>(new Map<string, any>());
    const [selectedExperiment, setSelectedExperiment] = useState<any>(null);
    const {experimentCode} = useParams<{experimentCode: string}>();
    const [openEditExperiment, setOpenEditExperiment] = useState(false);
    const [openNotTmtEdit, setOpenNotTmtEdit] = useState(false);
    const [openEditFlash, setOpenEditFlash] = useState(false)
    const [experimentDesign, setExperimentDesign] = useState<any>(null);
    const [analysis, setAnalysis] = useState<ExperimentAnalysis|FlashAnalysis|null>();
    const [experimentMessage, setExperimentMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [openEditSuccess, setOpenEditSuccess] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');
    const [clone, setClone] = useState(false);
    const [detailsDisplay, setDetailsDisplay] = useState<DetailsDisplay>({wideView: false})
    const referenceContext = useContext<References>(ReferenceContext)
    const [editable, setEditable] = useState(false);
    const [openDownloadMessage, setOpenDownloadMessage] = useState(false);
    const [downloadMessage, setDownloadMessage] = useState('');
    const [downloadStarted, setDownloadStarted] = useState(false);
    const [rowData, setRowData] = useState<any[]>([]);

    const theme = useTheme();
    const isQHDbnWQHD = useMediaQuery(theme.breakpoints.between(
        theme.breakpoints.values.qhd, theme.breakpoints.values.wqhd
    ));
    const isWQHDup = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.wqhd));
    
    useEffect(() => {
        if (experimentDesign && referenceContext.userName) {
            setEditable(designs.editableByUser(experimentDesign, referenceContext.userName))
        } else {
            setEditable(false)
        }
    }, [experimentDesign, referenceContext.userName])

    const handleEditSuccess = async (message: string, designType: ExperimentType) => {
        console.log(`Saved existing design of type ${designType.value} for ${selectedExperiment.experiment}`)
        setOpenEditSuccess(true);
        setSuccessMessage(message);
        if (experimentDesign.type !== designType.value) {
            // when editing on one details list type, handled like delete when type is changed
            const newExperiments = new Map(experiments)
            newExperiments.delete(selectedExperiment.experiment)
            if (newExperiments.size > 0) {
                const [firstValue] = newExperiments.values()
                setSelectedExperiment(firstValue)
            } else {
                setSelectedExperiment(null)
            }
            setExperiments(newExperiments);
        } else {
            try {
                const design = await details.fetchDesign(selectedExperiment.experiment)
                if (design) {
                    setExperimentDesign(design);
                }
            } catch (err) {
                console.error("Problem reloading design after edit.", err)
            }
        }
    }

    const handleCloneSuccess = async (message: string, designType: ExperimentType) => {
        setOpenEditSuccess(true);
        setSuccessMessage(message);
        //console.log(`in handleCloneSuccess with designType ${designType} vs ${experimentType}`)
        if (designType === experimentType) {
            try {
                const experimentsMap = await doFetch(experimentType.list, filters)
                setExperiments(experimentsMap)
            } catch (err) {
                console.error("Problem fetching new design after clone.", err)
            }
        }
    }

    const handleSuccessClose = (event?: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessMessage('');
        setOpenEditSuccess(false);
    };
    
    const doFetch = async (listRecordType: string, filter: ExperimentFilters) => {
        let experimentsList = await experimentsService.fetchExperimentStatusList(listRecordType, filter)
        experimentsList = experimentsList ? experimentsList.filter((e)=> e !== null) : []
        for (let i = 0; i < experimentsList.length; i++) {
            const experiment = experimentsList[i];
            const targetStatus = experiment!.status === 'PLANNED' ? 'PLANNED'
                : experiment!.status === 'PENDING' ? 'PENDING' :
                    experiment!.status === 'COMPLETE' ? 'COMPLETE' : 'PROCESSED';
            let status = experiment!.history.find((e: any) => e.status === targetStatus);
            // @ts-ignore
            experiment.date = new Date(status!.date);
        }
        // @ts-ignore
        experimentsList.sort(compareByDate);
        const experimentsMap = new Map<string, any>();
        experimentsList.forEach((exp: any) => experimentsMap.set(exp.experiment, exp))
        return experimentsMap;
    }

    const updateStatus = useCallback((experimentName: string, status: string) => {
        const toUpdate = experiments.get(experimentName)
        if (toUpdate) {
            const updated = {...toUpdate, status}
            // a deep copy of the map
            const copy: any[] = []
            experiments.forEach((value: any, key: string) => {
              copy.push([key, {...value}])
            })
            const newExperiments = new Map<string, any>(copy)
            newExperiments.set(updated.experiment, updated)
            setExperiments(newExperiments)
        }
    }, [experiments])

    const doReload = useCallback(async (current: string | undefined, data: any, filters: ExperimentFilters) => {
        if (current === data.type.code) {
            try {
                // console.log(`fixin to call doFetch with ${JSON.stringify(data)} and filters`, filters)
                // @ts-ignore
                const list = experimentTypes.find((type) => data.type.code === type.code).list
                const experimentsMap = await doFetch(list, filters)
                setExperiments(experimentsMap)
            } catch (err) {
                console.error('Error reloading experiments', err)
            }
        }
    }, [])

    useEffect(() => {
        const hubListenerCancel = Hub.listen('DesignChannel', async ({ payload: { event, data } }) => {
            console.log(`DesignChannel event: ${event} with data ${JSON.stringify(data)} and exp code ${experimentCode}`);
            switch (event) {
                case 'new':
                    await doReload(experimentCode, data, filters)
                    break;
                case 'started':
                    await doReload(experimentCode, data, filters)
                    break;
                default:
                    console.log('Unexpected event ' + event);
                    break;
            }
        });
        return () => {
            if (hubListenerCancel) {
                hubListenerCancel()
            }
        }
    }, [experimentCode, filters, doReload]);

    useEffect(() => {
        let subscription: any

        const observePublishing = () => {
            if (!subscription) {
                const publishingComplete = experimentsService.observePublishing()
                subscription = publishingComplete.subscribe({
                    next: function (result: any) {
                        setExperimentMessage('')
                        const subName = result.data.completedPublishStep.experimentName
                        if (result.data.completedPublishStep.status === "ERROR") {
                            const errorOptions: OptionsObject = {
                                variant: 'error',
                                persist: true
                            };
                            const err = result.data.completedPublishStep.message
                            enqueueSnackbar(`Publishing failed for ${subName}: ${err}`, errorOptions);
                            updateStatus(subName, 'PROCESSED')
                        } else {
                            const completedPublishStep = result.data.completedPublishStep
                            const successOptions: OptionsObject = {
                                variant: 'success',
                                persist: true,
                            };
                            const msg = `${completedPublishStep.status} ${completedPublishStep.message} for ${subName}`
                            enqueueSnackbar(msg, successOptions);
                            updateStatus(subName, 'PUBLISHED')
                        }
                    },
                    error(err: any) {
                        console.log(`PUB Subscription error`, err)
                    },
                    complete() {
                        console.log('PUB COMPLETE?')
                    }
                })
            }
        }
        observePublishing()
        const data = convertToRowData(experiments);
        setRowData(data)
        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        }
        // eslint-disable-next-line
    }, [experiments])

    useEffect(() => {
        let processedSubscription: any
        const fetchExperiments = async () => {
            setLoading(true);
            const type = experimentTypes.find(t => t.code === experimentCode);
            if (type && type.value !== experimentType?.value) {
                setExperimentType(type);
                setSelectedExperiment(null)
                setAnalysis(null)
                setExperimentDesign(null)
            }
            const listRecordType: string = type!.list;
            setTabValue(0);
            try {
                const experimentsMap = await doFetch(listRecordType, filters)
                if (experimentsMap.size > 0) {
                    setExperiments(experimentsMap);
                    // console.log(`The MAP`, experimentsMap)
                    const firstValue: any = experimentsMap.values().next().value
                    console.log(`The first value is ${firstValue?.experiment} in map`)
                    setSelectedExperiment(firstValue);
                    setLoading(false);
                } else {
                    setExperiments(experimentsMap);
                    setSelectedExperiment(null);
                    setLoading(false);
                }
            } catch (error) {
                setExperiments(new Map<string, any>());
                setSelectedExperiment(null);
                setAnalysis(null)
                setExperimentDesign(null)
                setLoading(false);
                console.log("Error fetching experiments list:", error);
            }

        }

        const observeProcessed = () => {
            if (!processedSubscription) {
                console.log('Creating a new subscription for processed experiments')
                const completedAnalysisStep = experimentsService.observeAnalysis()
                processedSubscription = completedAnalysisStep.subscribe({
                    next: function(result: any) {
                        console.log('completedAnalysisStep next called', result)
                            const experiment = result.data.completedAnalysisStep.experimentName
                            const code = codeFromName(experiment)
                            if (code === experimentCode) {
                                void fetchExperiments();
                            }
                            if (code === 'FDG') {
                                const exp = result.data.completedAnalysisStep.experimentName
                                const msg = result.data.completedAnalysisStep.status === 'OK'
                                        ? `${exp} processing completed`
                                        : `${exp} processing failed: ${result.data.completedAnalysisStep.message}`
                                const variant = result.data.completedAnalysisStep.status === 'OK' ? "success"
                                                    : "error"
                                enqueueSnackbar(msg, {variant, persist: true})
                            }
                    },
                    error(err: any) {
                        console.log(`processed Subscription error`, err)
                    },
                    complete() {
                        console.log('processed sub COMPLETE?')
                    }
                });
            } else {
                console.log(`processedSubscription is not null`, processedSubscription)
            }
        }

        void fetchExperiments();
        observeProcessed();

        return function cleanup(): void {
            processedSubscription.unsubscribe();
        }
    }, [filters, experimentCode, enqueueSnackbar, experimentType?.value]);

    useEffect(() => {
        const fetchDesign = async (experimentName: string) => {
            try {
                const design = await details.fetchDesign(experimentName);
                if (design) {
                    setExperimentDesign(design);
                } else {
                    const errorOptions: OptionsObject = {
                        variant: 'error',
                        persist: true
                    };
                    setExperimentDesign(null);
                    enqueueSnackbar("The selected experiment is missing its design data", errorOptions);
                }
            } catch (error) {
                console.log('Error fetching design')
            }
        };

        const fetchAnalysis = async (experimentName: string, experimentType: ExperimentType) => {
            const analysis: any = await details.fetchAnalysisForType(experimentName, experimentType);
            // console.log('Got the analysis', analysis)
            setAnalysis(analysis);
            if (analysis == null) {
                const errorOptions: OptionsObject = {
                    variant: 'error',
                    persist: true
                };
                enqueueSnackbar("The selected experiment is missing its analysis data", errorOptions);
            }
        };
        // old designs for different types can cause issues
        setExperimentDesign(null)
        if (selectedExperiment) {
            // The experimentCode gets updated well before the selectedExperiment.
            // Let's not waste effort with fetches just because the experimentCode changed.
            const match = selectedExperiment.experiment.match(/PX_([A-Z]{3})_[0-9]{5}_2[0-9]{3}/)
            if (match == null || match[1] === experimentCode) {
                fetchDesign(selectedExperiment.experiment).then();
                if (experimentType?.value && 'GENERAL' !== experimentType.value
                    && selectedExperiment.status !== 'PLANNED'
                    && selectedExperiment.status !== 'PENDING') {
                    fetchAnalysis(selectedExperiment.experiment, experimentType).then();
                } else {
                    setAnalysis(null);
                }
            }
        }
    }, [selectedExperiment, enqueueSnackbar, experimentType, experimentCode])

    const publishCallback = (status: string) => {
        if (status === 'PUBLISHING') {
            updateStatus(selectedExperiment.experiment, 'PUBLISHING')
            setExperimentMessage('Publishing may take a while...');
        } else {
            // publishing failed, so there will be a error toast and a status rollback.
            setExperimentMessage('')
            updateStatus(selectedExperiment.experiment, status)
        }
    }

    const handleDeletionSuccess = () => {
        const newExperiments = new Map(experiments)
        newExperiments.delete(selectedExperiment.experiment)
        if (newExperiments.size > 0) {
            const [firstValue] = newExperiments.values()
            setSelectedExperiment(firstValue)
        } else {
            setSelectedExperiment(null)
        }
        setExperiments(newExperiments);
    }

    const handleExperimentSelection = (params: GridRowParams) => {
        // console.log(`${params.id} wants to be CURRENT SELECTED ${JSON.stringify(selectedExperiment)}`)
        // prevents design and analysis from disappearing when you select an already selected experiment
        if (params.id !== selectedExperiment?.experiment) {
            setTabValue(0);
            setSelectedExperiment(experiments.get(params.id as string));
            setAnalysis(null)
            setExperimentDesign(null)
            setExperimentMessage('');
        }
    }

    function handleTabSelection(event: React.ChangeEvent<any>, value: any) {
        setTabValue(value);
    }

    const handleEditExperiment = async () => {
        // console.log(`the experiment type is ${JSON.stringify(experimentType)}`)
        if (experimentType?.value === 'FLASH_DEGRADATION' || experimentType?.value === 'FLASH_TURBO_ID') {
            setOpenEditFlash(true)
        } else {
            if (experimentDesign?.conditions.length > 0) {
                setOpenEditExperiment(true);
            } else {
                setOpenNotTmtEdit(true)
            }
        }
    }
    const spotfireDownload_sumPSMs = async () => {
        await download_on_the_fly(selectedExperiment.experiment, PXPfileType.SPOTFIRE, PXPAnalysisMethod.sum_PSMs);
    };
    const peptideDownload_sumPSMs = async () => {
        await download_on_the_fly(selectedExperiment.experiment, PXPfileType.PEPTIDE, PXPAnalysisMethod.sum_PSMs);
    }
    const spotfireDownload_MVITMP = async () => {
        await download_on_the_fly(selectedExperiment.experiment, PXPfileType.SPOTFIRE, PXPAnalysisMethod.MVI_TMP);
    };
    const peptideDownload_MVITMP = async () => {
        await download_on_the_fly(selectedExperiment.experiment, PXPfileType.PEPTIDE, PXPAnalysisMethod.MVI_TMP);
    }

    const download_on_the_fly = async (experiment: string, fileType: PXPfileType, method: PXPAnalysisMethod) => {
        const urlObj: any = await details.fetchOnTheFly(experiment, fileType, method);
        if (urlObj?.url) {
            setDownloadMessage('Download started');
            setDownloadStarted(true);
            // console.log('URL', urlObj)
            const a = document.createElement('a');
            a.href = urlObj.url;
            a.download = urlObj.key;
            a.click();
        } else {
            console.log(`DOWNLOAD FAILURE ${JSON.stringify(urlObj)}`)
            setDownloadMessage('Download failed');
            setDownloadStarted(false);
        }
    }
    
    const doSpotfireDownload_sumPSMs = () => { 
        setDownloadMessage('Preparing to download file...');
        setOpenDownloadMessage(true);
        spotfireDownload_sumPSMs();
    };
    const doPeptideDownload_sumPSMs = () => { 
        setDownloadMessage('Preparing to download file...');
        setOpenDownloadMessage(true);
        peptideDownload_sumPSMs();
    };
    const doSpotfireDownload_MVITMP = () => { 
        setDownloadMessage('Preparing to download file...');
        setOpenDownloadMessage(true);
        spotfireDownload_MVITMP();
    };
    const doPeptideDownload_MVITMP = () => { 
        setDownloadMessage('Preparing to download file...');
        setOpenDownloadMessage(true);
        peptideDownload_MVITMP();
    };

    const customToolBar = () => {
        return <GridToolbarContainer>
            <GridToolbarFilterButton data-cy={ExperimentDetailsPanelDataCy.gridFilters}/>
            <GridToolbarColumnsButton data-cy={ExperimentDetailsPanelDataCy.gridColumns}/>
        </GridToolbarContainer>
    }

    const action = (
        <Alert elevation={6} variant="filled" onClose={handleSuccessClose} severity="success">
            {successMessage}
        </Alert>
    )
    return (<ExperimentTypeContext.Provider value={experimentType}>
        <Grid container spacing={2}>
            <Grid key='Experiments' component="section" item xs={12} md={detailsDisplay.wideView ? 2 : 4}
                lg={detailsDisplay.wideView ? 1 : 4}
                xl={detailsDisplay.wideView ? 1 : !detailsDisplay.wideView && isQHDbnWQHD? 3 : isWQHDup? 2 : undefined}
                data-cy={ExperimentDetailsPanelDataCy.grid}
                sx={{ position: 'relative' }}
            >
                {!isWQHDup &&
                <Box position="absolute" right={0} top={20} zIndex={1}>
                    <Tooltip arrow title={detailsDisplay.wideView ? 'Minimize column width' : 'Expand column width'}>
                        <StyledColToggleButton size="small" color="secondary"
                            value="check"
                            selected={detailsDisplay.wideView}
                            onChange={() => {
                                setDetailsDisplay({...detailsDisplay, wideView: !detailsDisplay.wideView});
                            }}
                            disableRipple
                            sx={(theme: any) => ({
                                borderTopLeftRadius: theme.spacing(2),
                                borderBottomLeftRadius: theme.spacing(2),
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                            })}
                        >
                            {detailsDisplay.wideView? <MinimizeIcon /> : <ExpandIcon />}
                        </StyledColToggleButton>
                    </Tooltip>
                </Box>
                }
                <DataGrid autoHeight pagination rows={rowData} columns={COLUMNS}
                          onRowClick={handleExperimentSelection}
                          components={{Toolbar: customToolBar}}
                          getRowClassName={(params) =>
                              params.id === selectedExperiment?.experiment ? 'Mui-selected' : ''}
                />
            </Grid>
            <DisplayContext.Provider value={detailsDisplay}>
            <Grid key='details' component="section" item xs={12} md={detailsDisplay.wideView? 10 : 8}
                lg={detailsDisplay.wideView? 11 : 8}
                xl={detailsDisplay.wideView? 11 : !detailsDisplay.wideView && isQHDbnWQHD? 9 : isWQHDup? 10 : undefined}
            >
                {selectedExperiment ?
                <Card elevation={2} sx={{minHeight: 800}}>
                    {!['FLASH_TURBO_ID', 'FLASH_DEGRADATION'].includes(experimentType.value) ?
                    <>
                    <CardHeader
                        data-cy={ExperimentDetailsPanelDataCy.card}
                        title={selectedExperiment.experiment}
                        titleTypographyProps={{component: 'h2'}}
                        subheader={selectedExperiment.status}
                        subheaderTypographyProps={{component: 'p'}}
                        action={
                            <ExperimentMenu publishingCallback={publishCallback}
                                            experimentType={experimentType!}
                                            currentExperiment={selectedExperiment}
                                            design={experimentDesign}
                                            // using the lambda here is important due to how exportCallback is set in ImageExporter
                                            exportPdfCallback={() => exportPdfHandler.current()}
                                            setExperimentMessage={setExperimentMessage}
                                            spotfireDownloadHandler_sumPSMs={doSpotfireDownload_sumPSMs}
                                            spotfireDownloadHandler_MVITMP={doSpotfireDownload_MVITMP}
                                            peptideDownloadHandler_sumPSMs={doPeptideDownload_sumPSMs}
                                            peptideDownloadHandler_MVITMP={doPeptideDownload_MVITMP}
                                            deletionHandler={handleDeletionSuccess}
                                            editExperiment={handleEditExperiment}
                                            canDelete={editable}
                                            setClone={setClone}/>
                        }
                    />
                    {experimentMessage ? <Alert severity="info" sx={{borderRadius: 0}}>{experimentMessage}</Alert> : null}
                    {!loading &&
                    <ExperimentTabs experiment={selectedExperiment}
                                    experimentDesign={experimentDesign}
                                    experimentAnalysis={analysis as ExperimentAnalysis}
                                    tabValue={tabValue} handleTabSelection={handleTabSelection}
                                    exportPdfHandler={exportPdfHandler} setExperimentMessage={setExperimentMessage}/>
                    }
                    </> :
                        <FlashTabs experiment={selectedExperiment}
                                   experimentDesign={experimentDesign} tabValue={tabValue}
                                   handleTabSelection={handleTabSelection} exportPdfHandler={exportPdfHandler}
                                   setExperimentMessage={setExperimentMessage} analysis={analysis as FlashAnalysis}
                                   publishingCallback={publishCallback}
                                   deletionHandler={handleDeletionSuccess} editExperiment={handleEditExperiment}
                                   editable={editable} >
                            {experimentMessage ? <Alert severity="info" sx={{borderRadius: 0}}>{experimentMessage}</Alert> : null}
                        </FlashTabs>
                    }
                </Card>
                :
                <Card elevation={2} sx={{minHeight: 800}}>
                    <CardHeader
                        title='Details'
                        titleTypographyProps={{component: 'h2'}}
                        subheader='No experiments available'
                        subheaderTypographyProps={{component: 'p'}}
                    />
                </Card>
                }
            </Grid>
            </DisplayContext.Provider>
        </Grid>
        {selectedExperiment && openEditExperiment
            && <CreateNewExperimentModal handleClose={() => setOpenEditExperiment(false)}
            experimentDesign={experimentDesign}
            existingExperimentName={selectedExperiment && selectedExperiment.experiment}
            handleSuccess={handleEditSuccess}/>}
        {selectedExperiment && openNotTmtEdit
            && <CreateNotTmtExperimentModal handleClose={() => setOpenNotTmtEdit(false)}
                                            handleSuccess={handleEditSuccess}
                                            existingExperimentName={selectedExperiment.experiment}
                                            experimentDesign={experimentDesign}
            />
        }
        {selectedExperiment && openEditFlash
            && <CreateNewFlashExperimentModal handleClose={() => setOpenEditFlash(false)}
                                              handleSuccess={handleEditSuccess}
                                              existingExperimentName={selectedExperiment.experiment}
                                              experimentDesign={experimentDesign} />
        }
        {clone && (experimentDesign?.conditions?.length > 0) &&
            <CreateNewExperimentModal handleClose={() => setClone(false)}
                                      experimentDesign={experimentDesign}
                                      handleSuccess={handleCloneSuccess}/>}

        {clone && isEmpty(experimentDesign?.conditions) &&
            <CreateNotTmtExperimentModal handleClose={() => setClone(false)}
                                         handleSuccess={handleCloneSuccess}
                                         experimentDesign={experimentDesign}
            />}
        <Snackbar
            open={openEditSuccess}
            autoHideDuration={6000}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            onClose={handleSuccessClose}
            action={action}
        />
        <Snackbar 
            open={openDownloadMessage}
            autoHideDuration={downloadStarted? 2000 : null}
            anchorOrigin={{ horizontal: 'center', vertical: 'top'}}
            onClose={() => {
                setDownloadMessage('');
                setOpenDownloadMessage(false);
                setDownloadStarted(false);
            }}
            ClickAwayListenerProps={{ onClickAway: () => null }}
            disableWindowBlurListener
        >
            <Alert elevation={6} severity="info" sx={{width: 400, display: 'flex', alignItems: 'center'}}>
                {downloadMessage}
                {downloadStarted? null : <LinearProgress color="inherit" />}
            </Alert>
        </Snackbar>
    </ExperimentTypeContext.Provider>)
}

// prepare data for data grid
function convertToRowData(experiments: Map<string, any>) {
    const rowData = new Array(experiments.size)
    let index = 0
    experiments.forEach((exp) => {
        rowData[index] = {id: exp.experiment,
            experiment: exp.injectionNumber || exp.experiment,
            date: exp.date,
            status: exp.status}
        index++
    })
    // console.log('Row Data', rowData)
    return rowData
}
function areEqual(prevProps: any, nextProps: any) {
    return prevProps.filters === nextProps.filters;
}
export default memo(ExperimentDetailsPanel, areEqual);
