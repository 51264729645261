import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import Dashboard from './components/Dashboard';
import * as serviceWorker from './serviceWorker';
import {Amplify} from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json'
import CssBaseline from "@mui/material/CssBaseline";
import {AxiosInterceptor} from './config/AxiosInterceptor';
import {closeSnackbar, SnackbarProvider} from 'notistack';
import {Button} from '@mui/material';
import {AppThemeProvider} from './theme/AppThemeProvider';
import {createRoot} from 'react-dom/client';

Amplify.configure(amplifyconfig)

const notistackRef = React.createRef<SnackbarProvider>();

// notistack cannot access app theme
// it applies styles from default MUI theme in its own notistack classes
const sxNotistackActionBtn = {
    color: '#FFFFFF',
    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.2)'}
} as const;

const container = document.getElementById('root')
// @ts-ignore
const root = createRoot(container)
root.render(
    <BrowserRouter>
        <StyledEngineProvider injectFirst>
            <AppThemeProvider>
                <SnackbarProvider maxSnack={4} anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                  ref={notistackRef}
                                  action={(key: any) => (
                                    <Button
                                        onClick={() => closeSnackbar(key)}
                                        variant="text"
                                        sx={sxNotistackActionBtn}
                                    >
                                        Dismiss
                                    </Button>
                                  )}>
                    <AxiosInterceptor />
                    <CssBaseline/>
                    <Dashboard/>
                </SnackbarProvider>
            </AppThemeProvider>
        </StyledEngineProvider>
    </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
