import {generateClient} from 'aws-amplify/api'
import {completeExperiment, deleteExperiment, revertExperiment, publishExperiment} from '../graphql/mutations';
import {handleError} from '../util/util';

const menuServices = {
    setComplete: async (experiment: string, experimentType: ExperimentType): Promise<boolean> => {
        const variables = {experimentName: experiment, experimentType: experimentType.value, status: 'COMPLETE'}
        try {
            const client = generateClient()
            const result = await client.graphql({query: completeExperiment, variables: variables})
            // console.log(`RESULT ${JSON.stringify(result)}`)
            return result?.data.completeExperiment === 'OK'
        } catch (error) {
            console.error(`Error setting complete ${experiment}`, error)
            handleError(error)
        }
        return false
    },
    revertToPlanned: async (experiment: string, experimentType: ExperimentType): Promise<void> => {
        const variables = {experimentName: experiment, experimentType: experimentType.value};
        try {
            const client = generateClient()
            await client.graphql({query: revertExperiment, variables: variables})
        } catch(error) {
            console.log("Error reverting experiment: " + experiment, error);
            handleError(error)
        }
    },
    deleteExperiment: async (experiment: string, experimentType: ExperimentType): Promise<void> => {
        const variables = {experimentName: experiment, experimentType: experimentType.value};
        let result: any
        try {
            const client = generateClient()
            result = await client.graphql({query: deleteExperiment, variables})
        } catch(error) {
            console.log("Error deleting experiment: " + experiment, error);
            handleError(error)
        }
        return result
    },
    publish: async (experiment: string, experimentType: ExperimentType): Promise<any> => {
        try {
            const input = {
                experimentName: experiment,
                significanceMeasure: 'P_VALUE',
                experimentType: experimentType.value
            }
            const result = await generateClient().graphql({query: publishExperiment, variables: {input}})
            return result.data.publishExperiment
        } catch (error) {
            console.error('publish Error', error)
            handleError(error)
            return {status: 'FAILED'}
        }
    }
}

export default menuServices