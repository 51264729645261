import {generateClient} from 'aws-amplify/api';
import {deleteBarcodePair, updateBarcodePair} from '../graphql/mutations';
import {handleError} from '../util/util';


const barcodeService = {
    updateBarcode: async (parent: string, child: string) => {
        try {
            const variables = {parent, child}
            const result = await generateClient().graphql({query: updateBarcodePair, variables})
            return result.data.updateBarcodePair
        } catch (error) {
            console.error('updateBarcode Error:', error);
            handleError(error)
            return undefined
        }
    },
    deleteBarcode: async (parent: string, child: string) => {
        try {
            const result = await generateClient().graphql({query: deleteBarcodePair, variables: {parent, child}})
            return result.data.deleteBarcodePair
        } catch (error) {
            console.error('deleteBarcode Error:', error);
            handleError(error)
        }
        return undefined
    }
}

export default barcodeService