import React, {FC, useCallback, useContext, useEffect, useState} from 'react'
import {
    Warning as WarningIcon
} from '@mui/icons-material';
import {Box, CardHeader, Tab, Tabs} from '@mui/material';
import {ExperimentTabsDataCy} from './ExperimentTabs.cy';
import ImageExporter from '../../../../../util/ImageExporter';
import {ExperimentTabsProps} from './ExperimentTabs';
import {TAB_FILE_NAMES} from '../../../../../util/options'
import FlashPlotsTabPanel from './FlashPlotsTabPanel';
import flashService from '../../../../../services/flashService';
import {OptionsObject, useSnackbar} from 'notistack';
import FlashXcaliburAlert from './FlashXcaliburAlert';
import FlashDetails from './FlashDetails';
import FlashExperimentMenu from '../ExperimentMenu/FlashExperimentMenu';
import {FlashAnalysis, FlashStatistics} from '../../../../../API';
import {ExperimentTypeContext} from '../../../../../store/ExperimentTypeContext';

const errorOptions: OptionsObject = {
    variant: 'error',
    persist: true
};

interface FlashTabsProps extends ExperimentTabsProps {
    analysis: FlashAnalysis | null | undefined;
    publishingCallback: (status: string) => void;
    deletionHandler: () => void;
    editExperiment: () => void;
    editable: boolean;
    children?: React.ReactNode;

}

const FlashTabs: FC<FlashTabsProps> = ({experiment,
                                           experimentDesign,
                                           tabValue, handleTabSelection, exportPdfHandler,
                                           setExperimentMessage,
                                           analysis,
                                           publishingCallback,
                                           deletionHandler,
                                           editExperiment,
                                           editable,
                                           children}) => {
    const [ready, setReady] = useState(!!experimentDesign?.flashWarningsAcknowledged)
    const [subscriptionResult, setSubscriptionResult] = useState<any>(null)
    const [sampleResults, setSampleResults] = useState<any>(undefined)
    const [stats, setStats] = useState<FlashStatistics | undefined>()
    const {enqueueSnackbar} = useSnackbar();
    const experimentType = useContext<ExperimentType>(ExperimentTypeContext)

    // console.log(`exportReady is ${ready} experimentDesign`, experimentDesign)

    const fetchInfo = useCallback(async (expName: string) => {
        const data = await flashService.fetchSampleListResults(expName, experimentType)
        // console.log(`${expName} THE JSON INFO`, data)
        if (data === null) {
            // console.log('setting sampleResults to null')
            setSampleResults(null)
        } else {
            setSampleResults(data)
            if (!data.experiment_properties.warnings
                || data.experiment_properties.warnings.length === 0) {
                setReady(true)
            } else {
                if (experimentDesign?.flashWarningsAcknowledged) {
                    setReady(true)
                } else {
                    setReady(false)
                }
            }
        }
    }, [experimentDesign?.flashWarningsAcknowledged, experimentType])

    useEffect(() => {
        const observable = flashService.subscribeToFlashSampleList()
        const subscription = observable.subscribe({
            next: function (result: any) {
                console.log(`completeFlashSampleList: ${JSON.stringify(result)}`)
                console.log('in short', result.data.completeFlashSampleList)
                if (result.data.completeFlashSampleList.status === 'ERROR') {
                    const msg = 'Processing error preparing '
                        + result.data.completeFlashSampleList.experimentName
                        + ". " + result.data.completeFlashSampleList.message
                    enqueueSnackbar(msg, errorOptions)
                }
                setSubscriptionResult(result.data.completeFlashSampleList)
            }
        })

        return function cleanup(): void {
            subscription.unsubscribe()
        }
        // just subscribe on page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setReady(false)
        setSampleResults(undefined)
        if (experimentDesign?.peptideBarcode) {
            console.log(`fetching sample List Info for ${experiment.experiment}`)
            fetchInfo(experiment.experiment)
        }
    }, [experiment, fetchInfo, experimentDesign?.peptideBarcode])

    useEffect(() => {
        console.log(`Experiment is ${experiment.experiment} and latest sub is ${JSON.stringify(subscriptionResult)}`)
        if (subscriptionResult?.experimentName === experiment.experiment) {
            console.log(`We HAVE A MATCH!!! with status ${subscriptionResult.status}`)
            if (subscriptionResult.status === 'OK') {
                fetchInfo(experiment.experiment)
            }
        }
    }, [experiment, subscriptionResult, fetchInfo])

    useEffect(() => {
        const updateStats = async (currentAnalysis: FlashAnalysis) => {
            const flashStats = await flashService.fetchStats(currentAnalysis.experiment, experimentType.statsType)
            setStats(flashStats)
        }
        if (analysis) {
            // if we have an analysis record, then we should also have a statistics record for the exp.
            void updateStats(analysis)
        } else {
            setStats(undefined)
        }
    }, [analysis, experimentType.statsType])

    const saveAcknowledgement = async (ackUser: string) => {
        if (ackUser) {
            setReady(true)
        } else {
            setReady(false)
        }
        await flashService.saveAcknowledgement(experiment.experiment, ackUser)
    }

    if (!experiment) {
        return null
    }

    return (<>
    <CardHeader
        title={experiment.experiment}
        titleTypographyProps={{component: 'h2'}}
        subheader={experiment.status}
        subheaderTypographyProps={{component: 'p'}}
        action={<FlashExperimentMenu publishingCallback={publishingCallback}
                                     currentExperiment={experiment}
                                     exportPdfCallback={() => exportPdfHandler.current()}
                                     setExperimentMessage={setExperimentMessage}
                                     deletionHandler={deletionHandler}
                                     editExperiment={editExperiment}
                                     canDelete={editable}
                                     canProcess={ready}
                                     analysis={analysis}
        />}
    />
    {children}
    {experimentDesign?.peptideBarcode && <FlashXcaliburAlert experiment={experiment}
                        design={experimentDesign} sampleResults={sampleResults}
                        saveAcknowledgement={saveAcknowledgement} tabValue={tabValue}/>}
    <Box sx={{width: '100%'}}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs value={tabValue} onChange={handleTabSelection} aria-label="Tabs of Data for experiment">
                <Tab data-cy={ExperimentTabsDataCy.detailsTab} label="Experiment Details" id="DetailsTab" aria-controls="DetailsTabPanel"/>
                {sampleResults &&
                <Tab data-cy={ExperimentTabsDataCy.plotsTab} label="Plots & Stats" id="PlotsTab" aria-controls="FlashPlotsTabPanel"
                    icon={sampleResults?.experiment_properties.warnings ? <WarningIcon fontSize="small" /> : ''} iconPosition="end"
                />}
            </Tabs>
        </Box>
        <ImageExporter fileName={`${experiment.experiment}_${TAB_FILE_NAMES[tabValue]}`} exportTrigger={exportPdfHandler}
                       cleanUpFunction={() => setExperimentMessage('')}>
            {tabValue === 0 ? <FlashDetails  experiment={experiment}
                                             design={experimentDesign}
                                             xcaliburReady={!!sampleResults}
                                             exportReady={ready}
                                             analysis={analysis}
                                             statistics={stats}
            />: <></>}
            {tabValue === 1 ? <FlashPlotsTabPanel experiment={experiment}
                                                  sampleResults={sampleResults} /> : <></>}
        </ImageExporter>
    </Box>
    </>
    )
}

export default FlashTabs