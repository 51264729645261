import {createContext} from 'react';

export const ExperimentTypeContext = createContext<ExperimentType>({
    value: '',
    label: '',
    code: '',
    statsType: '',
    list: '',
    publish: false,
    upload: false,
    filterable: false,
    disable: true,
    lcms: 0
})