import {useCallback, useContext, useMemo, useRef, useState} from 'react';
import {Box, Button, Typography, useTheme} from '@mui/material';
import {
    DataGrid,
    DataGridProps,
    GridColDef,
    GridRowId,
    GridValidRowModel,
    useGridApiRef,
    GridActionsCellItem,
    GridToolbar
} from '@mui/x-data-grid';
import { columns } from './utils/XcaliburGridColDef';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save'
import RestoreIcon from '@mui/icons-material/Restore'
import DeleteIcon from '@mui/icons-material/Delete'
import designs from '../../../../../services/designs'
import flashService from '../../../../../services/flashService';
import {Download} from '@mui/icons-material';
import {alpha, lighten, styled} from '@mui/material/styles';
import SquareIcon from '@mui/icons-material/SquareRounded';
import isEqual from 'lodash/isEqual'
import patternDiagonalLines from '../../../../../static/patternDiagonalLines.svg'
import {ExperimentTypeContext} from '../../../../../store/ExperimentTypeContext';

interface FlashXcaliburPanelProps {
    experiment: {experiment: string, status: string};
    xcaliburRows: any;
    xcaliburHeader: string;
    exportReady: boolean;
}
const baseDownloadTxt = 'Download Samples for Astral'
const saveAndDownloadTxt = 'Save and Download Samples for Astral'
const getBackgroundColor = (color: string) => lighten(color, 0.7)
const getHoverBackgroundColor = (color: string) => lighten(color, 0.6)
const getSelectedBackgroundColor = (color: string) => lighten(color, 0.5)
const getSelectedHoverBackgroundColor = (color: string) => lighten(color, 0.4)

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .xcalibur-grid-theme': {
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.info.main)
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(theme.palette.info.main),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(theme.palette.info.main)
            }
        }
    },
    '& .xcalibur-grid-theme-processed': {
        backgroundColor: getBackgroundColor(theme.palette.success.main),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.success.main)
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(theme.palette.success.main),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(theme.palette.success.main)
            }
        }
    },
    '& .row--removed': {
        background: `${getBackgroundColor(theme.palette.secondary.light)} url(${patternDiagonalLines}) repeat`,
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.secondary.light)
        }
    },
    '& .row--edited': {
        backgroundColor: getBackgroundColor(theme.palette.warning.light),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.warning.light)
        }
    },
    '& input': {width: '100%'}

}))

export default function FlashXcaliburPanel({experiment, xcaliburRows, xcaliburHeader, exportReady}: FlashXcaliburPanelProps) {
    const theme = useTheme()
    const apiRef = useGridApiRef()
    const [hasUnsavedRows, setHasUnsavedRows] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState(false)
    const [downloadTxt, setDownloadTxt] = useState(baseDownloadTxt)
    const experimentTypeCtx = useContext<ExperimentType>(ExperimentTypeContext)
    const unsavedChangesRef = useRef<{
        unsavedRows: Record<GridRowId, GridValidRowModel>;
        rowsBeforeChange: Record<GridRowId, GridValidRowModel>;
    }>({
        unsavedRows: {},
        rowsBeforeChange: {}
    })
    // console.log(window.navigator.userAgent)

    const saveChanges = useCallback(async () => {
        // this is important, because if rows are selected, only they get saved
        apiRef.current.setRowSelectionModel([])
        try {
            setIsSaving(true)
            const rowsToDelete = Object.values(
                unsavedChangesRef.current.unsavedRows,
            ).filter((row) => row._action === 'delete');
            if (rowsToDelete.length > 0) {
                rowsToDelete.forEach((row) => {
                    apiRef.current.updateRows([row]);
                });
            }
            const fileData = apiRef.current.getDataAsCsv()
            const typePath = experimentTypeCtx.value === 'FLASH_DEGRADATION' ? 'deg' : 'trb'
            const key = `flash/${typePath}/sample-list/${experiment.experiment}-temp.csv`
            const csvFileUrl = await designs.getFlashUploadUrl(key)
            const blob = new Blob([fileData], {type: 'binary/octet-stream'})
            const file = new File([blob], 'temp.csv', {type: 'binary/octet-stream'})
            if (csvFileUrl) {
                await designs.doFlashUpload(csvFileUrl, file)
                const result = await flashService.updateFlashSampleList(experiment.experiment, experimentTypeCtx.value)
                console.log(`The update result: ${result}`)
                if (result) {
                    setHasUnsavedRows(false)
                    setDownloadTxt(baseDownloadTxt)
                    unsavedChangesRef.current = {
                        unsavedRows: {},
                        rowsBeforeChange: {}
                    }
                }
            }
            setIsSaving(false)
        } catch (err) {
            console.error('SaveCsvChanges FAILED', err)
            setIsSaving(false)
        }
    }, [apiRef, experiment.experiment, experimentTypeCtx.value])

    const doExport = useCallback(async () => {
        console.log('doExport')
        if (hasUnsavedRows) {
            await saveChanges()
        }
        const fileData = xcaliburHeader + apiRef.current.getDataAsCsv()
        const a = document.createElement('a')
        const file = new Blob([fileData], {type: 'text/csv'})
        a.href = URL.createObjectURL(file)
        a.download = experiment.experiment + '.csv'
        a.click()
    }, [apiRef, experiment.experiment, hasUnsavedRows, saveChanges, xcaliburHeader])

    const discardChanges = useCallback(() => {
        setHasUnsavedRows(false)
        setDownloadTxt(baseDownloadTxt)
        Object.values(unsavedChangesRef.current.rowsBeforeChange).forEach((row) => {
            apiRef.current.updateRows([row])
        })
        unsavedChangesRef.current = {
            unsavedRows: {},
            rowsBeforeChange: {}
        }
    }, [apiRef])

    const processRowUpdate = useCallback<NonNullable<DataGridProps['processRowUpdate']>>((newRow, oldRow) => {
        const rowId = newRow['File Name'];
        // console.log(`${rowId} is the row`, newRow)
        const equal = isEqual(newRow, oldRow)
        // console.log(`In processRowUpdate with equal objects ${equal}`)
        if (!equal) {
            unsavedChangesRef.current.unsavedRows[rowId] = newRow;
            if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
                unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
            }
            setHasUnsavedRows(true);
            setDownloadTxt(saveAndDownloadTxt)
        }
        return newRow;
    }, [])
    const getRowClassName = useCallback<NonNullable<DataGridProps['getRowClassName']>>(({id, row}) => {
        const unsavedRow = unsavedChangesRef.current.unsavedRows[id];
        if (unsavedRow) {
            //console.log(`Row id is ${id}`, row)
            if (unsavedRow._action === 'delete') {
                return 'row--removed';
            }
            return 'row--edited';
        }
        return row.processed ? 'xcalibur-grid-theme-processed': 'xcalibur-grid-theme';
    }, [])
    const myColumns = useMemo<GridColDef[]>(() => {
        return [{
            field: 'actions',
            type: 'actions',
            getActions: ({id, row}) => {
            return [
                <GridActionsCellItem
                        key={`${id}RestoreAction`}
                        icon={<RestoreIcon/>}
                        label="Discard changes"
                        disabled={unsavedChangesRef.current.unsavedRows[id] === undefined}
                        onClick={() => {
                            apiRef.current.updateRows([
                                unsavedChangesRef.current.rowsBeforeChange[id],
                            ]);
                            delete unsavedChangesRef.current.rowsBeforeChange[id];
                            delete unsavedChangesRef.current.unsavedRows[id];
                            const unsavedExists = Object.keys(unsavedChangesRef.current.unsavedRows).length > 0
                            setHasUnsavedRows(unsavedExists);
                            if (!unsavedExists) {
                                setDownloadTxt(baseDownloadTxt)
                            }
                        }}
                />,
                <GridActionsCellItem
                    key={`${id}DeleteAction`}
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => {
                        unsavedChangesRef.current.unsavedRows[id] = {
                            ...row,
                            _action: 'delete',
                        };
                        if (!unsavedChangesRef.current.rowsBeforeChange[id]) {
                            unsavedChangesRef.current.rowsBeforeChange[id] = row;
                        }
                        setHasUnsavedRows(true);
                        setDownloadTxt(saveAndDownloadTxt)
                        apiRef.current.updateRows([row]); // to trigger row render
                    }}
                />,
                ]
            }
        },
        ...columns
        ]
    }, [unsavedChangesRef, apiRef])

    return (
        <Box ml={2} mt={1}>
            {(experiment.status === 'PROCESSED' || experiment.status === 'PUBLISHED') &&
                <Box display="flex" alignItems="center" mt={-0.5} mb={1.5}>
                    <SquareIcon fontSize="small" sx={{
                        color: lighten(theme.palette.success.light, 0.5),
                        stroke: alpha(theme.palette.success.light, 0.7),
                        strokeWidth: 1
                    }} />
                    <Typography component="p" variant="body2" ml={0.5}>Processed Rows</Typography>
                </Box>
            }
            {hasUnsavedRows && <>
                <Box display="flex" alignItems="center" mt={-0.5} mb={1.5} position="relative">
                    <SquareIcon fontSize="small" sx={{
                        color: lighten(theme.palette.secondary.light, 0.7),
                        stroke: alpha(theme.palette.secondary.light, 0.7),
                        strokeWidth: 1
                    }} />
                    <Typography component="p" variant="body2" ml={0.5}>To Be Removed on Save</Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={-0.5} mb={1.5}>
                    <SquareIcon fontSize="small" sx={{
                        color: lighten(theme.palette.warning.light, 0.5),
                        stroke: alpha(theme.palette.warning.light, 0.7),
                        strokeWidth: 1
                    }} />
                    <Typography component="p" variant="body2" ml={0.5}>To Be Updated on Save</Typography>
                </Box>
            </>
            }
            <Typography variant="body2">
                Export (download) of sample list is disabled until warnings and errors are acknowledged.
            </Typography>
            <Box mt={1} width="100%">
                <Button disabled={!exportReady || isSaving}
                        onClick={doExport}
                        startIcon={<Download />}
                >{downloadTxt}</Button>
                <LoadingButton disabled={!hasUnsavedRows}
                               loading={isSaving}
                               onClick={saveChanges}
                               startIcon={<SaveIcon />}
                               loadingPosition="start">
                    <span>Save changes</span>
                </LoadingButton>
                <Button disabled={!hasUnsavedRows || isSaving}
                        onClick={discardChanges}
                        startIcon={<RestoreIcon />}>
                    Discard all changes
                </Button>

            </Box>
            <Box mt={1} height={600} width="100%">
                <StyledDataGrid
                    rows={xcaliburRows}
                    columns={myColumns}
                    apiRef={apiRef}
                    disableRowSelectionOnClick
                    checkboxSelection
                    unstable_ignoreValueFormatterDuringExport
                    // grid won't render in tests without this being true, performance is terrible in browser with it being false
                    disableVirtualization={window.navigator.userAgent.includes('jsdom')}
                    processRowUpdate={processRowUpdate}
                    getRowId={(row) => row['File Name']}
                    slots={{
                        toolbar: GridToolbar
                    }}
                    slotProps={{
                        toolbar: {
                            csvOptions: {disableToolbarButton: true},
                            printOptions: {disableToolbarButton: true},
                        }
                    }}
                    pagination
                    loading={isSaving}
                    getRowClassName={getRowClassName}
                />
            </Box>
        </Box>
    );
}