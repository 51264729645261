import {generateClient} from 'aws-amplify/api'
import {completeFlashSampleList} from '../graphql/subscriptions';
import {
    getBarcodeInfo,
    getExperimentDownloadUrl,
    getProcessableFlashSamples,
    getFlashStats,
    keyExists
} from '../graphql/queries';
import {acknowledgeFlashWarnings, startFlashAnalysis, updateFlashSampleList} from '../graphql/mutations'
import {ExperimentDownload} from '../API';
import * as d3 from 'd3-fetch';
import * as dsv from 'd3-dsv'
import {handleError} from '../util/util';

const flashService = {
    subscribeToFlashSampleList: () => {
        return generateClient().graphql({query: completeFlashSampleList})
    },
    fetchSampleListResults: async (experiment: string, type: ExperimentType): Promise<any> => {
        const typePath = type.value === 'FLASH_DEGRADATION' ? 'deg' : 'trb'
        console.log(`fixing to fetchSampleListResults for ${experiment}`)
        const key = `flash/${typePath}/w-assay/results/${experiment}.json`
        try {
            const result = await generateClient().graphql({
                query: getExperimentDownloadUrl,
                variables: {keys: [key], experimentDownload: ExperimentDownload.FLASH}
            })
            try {
                if (result.data.getExperimentDownloadUrl) {
                    const data = result.data.getExperimentDownloadUrl[0]
                    if (data) {
                        const jsonData = await d3.json(data.url)
                        return jsonData
                    }
                }
            } catch (err) {
                console.error(`${key} ERROR`, err)
                handleError(err)
            }
        } catch (error) {
            console.error('fetchSampleListResults Error:', error);
            handleError(error)
        }
        return null
    },
    fetchXcaliburSampleList: async (experiment: string, type: ExperimentType): Promise<any> => {
        const typePath = type.value === 'FLASH_DEGRADATION' ? 'deg' : 'trb'
        const key = `flash/${typePath}/sample-list/${experiment}.csv`
        const result = await generateClient().graphql({query: getExperimentDownloadUrl,
            variables: { keys: [key], experimentDownload: ExperimentDownload.FLASH}})
        try {
            if (result.data.getExperimentDownloadUrl && result.data.getExperimentDownloadUrl[0]) {
                const rawData = await d3.text(result.data.getExperimentDownloadUrl[0].url)
                // Throw away the first three lines
                let n = 3
                let i = -1
                while (n-- && i++ < rawData.length) {
                    i = rawData.indexOf('\n', i)
                    if (i < 0) {
                        break
                    }
                }
                const headerData = rawData.substring(0, i + 1)
                const rowData = dsv.csvParse(rawData.substring(i + 1))
                return {header: headerData, rows: rowData}
            }
        } catch (err) {
            console.error(`fetchXcaliburSampleList Errror for ${experiment}`, err)
            handleError(err)
        }
        return null
    },

    saveAcknowledgement: async (experiment: string, ackUser: string): Promise<any> => {
        try {
            const designResult = await generateClient().graphql({query: acknowledgeFlashWarnings,
                variables: {experimentName: experiment, user: ackUser}})
            // console.log(`saveAcknowledgement RESULT`, designResult)
            return designResult.data
        } catch (error) {
            console.error(`saveAcknowledgement Error for ${experiment}`, error)
            handleError(error)
            return null
        }
    },
    updateFlashSampleList: async (experiment: string, experimentType: string): Promise<any> => {
        try {
            const results = await generateClient().graphql({query: updateFlashSampleList,
                variables: {experimentName: experiment, experimentType}})
            return results.data.updateFlashSampleList
        } catch (error) {
            console.error('updateFlashSampleList Error', error)
            handleError(error)
            return null
        }
    },
    getBarcodeInfo: async (peptideBarcode: string): Promise<any> => {
        try {
            const results = await generateClient()
                .graphql({query: getBarcodeInfo, variables: {peptideBarcode: peptideBarcode}})
            // console.log(results)
            // The data is coming double wrapped
            // ie results.data.getBarcodeInfo is a string after the first
            // parse, necessitating the second parse
            if (results.data.getBarcodeInfo) {
                return JSON.parse(JSON.parse(results.data.getBarcodeInfo))
            }
        } catch (error) {
            console.error('getBarcodeInfo Error', error)
            handleError(error)
            return null
        }
        return null
    },
    getProcessableSamples: async (experiment: string): Promise<FlashSample[]> => {
        try {
            const results = await generateClient().graphql({query: getProcessableFlashSamples,
                variables: {experiment}})
            return results.data.getProcessableFlashSamples as unknown as Promise<FlashSample[]>
        } catch (error) {
            console.error('getBarcodeInfo Error', error)
            handleError(error)
            return []
        }
    },
    processExperiment: async (experiment: string,
                              experimentType: string,
                              cutoff: number,
                              samples: string[]): Promise<any> => {
        try {
            const results = await generateClient().graphql({query: startFlashAnalysis,
                variables: {
                    input: {
                        experimentName: experiment,
                        experimentType,
                        transferQvalueCutoff: cutoff.toString(),
                        samples
                    }
                }})
            // console.log('MY RESULTS: ', results)
            return results.data.startFlashAnalysis
        } catch (error) {
            console.error('processExperiment Error', error)
            handleError(error)
            return {}
        }

    },
    fetchStats: async (experiment: string, statsType: string): Promise<any> => {
        // console.log(`DO fetchStats for ${experiment}`)
        try {
            const results = await generateClient().graphql({query: getFlashStats,
                variables: {experiment: experiment, recordType: statsType}})
            // console.log('Flash STATS RESULTS', results)
            return results.data.getFlashStats
        } catch (error) {
            console.error('processExperiment Error', error)
            handleError(error)
            return undefined
        }

    },
    hasTcanFile: async (key: string): Promise<boolean> => {
        try {
            const results: any = await generateClient().graphql({query: keyExists,
                variables: {key, bucketType: ExperimentDownload.FLASH}})
            return results.data.keyExists
        } catch (error) {
            console.error('processExperiment Error', error)
            handleError(error)
            return false
        }
    },
    getTcanKey(type: ExperimentType, experimentName: string) {
        const typePath = type.value === 'FLASH_DEGRADATION' ? 'deg' : 'trb'
        return `flash/${typePath}/w-assay/fluorescence/${experimentName}.csv`
    }
}

export default flashService